@charset "UTF-8";

@use '@angular/material' as mat;

// Fuse
@import 'pay';

// Base Variables
@import 'base/variables';

// Base Mixins
@import 'mixins/svg';
@import 'mixins/transitions';
@import 'mixins/animate';

// site wide
@import 'layout/defaults';
@import 'base/helpers';
@import 'layout/fonts';
//@import '~bootstrap/scss/bootstrap';

// Theming
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Include theme styles for core and each component used in your app.
@include mat.all-legacy-component-themes($theme);

// vendors
@import 'ngx-toasta/styles/style-bootstrap.css';
@import 'vendor/fullcalendar';
@import 'vendor/slim-loader';
@import '@circlon/angular-tree-component/css/angular-tree-component.css';

// app specific
@import 'partials/app';

// plugins
@import 'partials/plugins/plugins';

// material
@import 'vendor/material_override';
@import 'vendor/material_dialogs';
@import 'vendor/material_table';
@import 'vendor/material_chip';
@import 'vendor/material_form';
@import 'vendor/material_selection_list';

// bootstrap
@import 'vendor/bootstrap_override';

.payhoa-loading {
    align-items: flex-start;
    background-color: rgba($white, 0.6);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 150;

    .mat-progress-spinner {
        margin-top: 75px;
    }
}

.grid-layout {
    display: grid;
    gap: 20px;
    margin-top: 20px;

    .card {
        margin: 0;
    }

    /** Default is 4 up */
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 1199px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 500px) {
        grid-template-columns: 1fr;
    }

    /** Easy option for 2 up */
    &.grid-2 {
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: 1300px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    /** Alternate option for 2 up (two column with fixed right panel") */
    &.grid-2-right-panel {
        grid-template-columns: 2.5fr 1.5fr;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    /** Alternate option for 2 up (two column with fixed left panel") */
    &.grid-2-left-panel {
        grid-template-columns: 1fr 3fr;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    /** Easy option for 3 up */
    &.grid-3 {
        grid-template-columns: repeat(3, 1fr);

        @media (max-width: 1300px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    /** Alternate option for 3 up (two column with middle fixed to represent "or") */
    &.grid-3-middle-fixed {
        grid-template-columns: 1fr 5rem 1fr;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    /** Easy option for 5 up */
    &.grid-5 {
        grid-template-columns: repeat(5, 1fr);

        @media (max-width: 1500px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width: 1236px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 500px) {
            grid-template-columns: 1fr;
        }
    }

    /** Helpers for a few of the grid layout children */
    .grid-col-span-2 {
        grid-column: span 2;
    }

    .grid-col-span-3 {
        grid-column: span 3;
    }

    .grid-col-span-4 {
        grid-column: span 4;

        @media (max-width: 1199px) {
            grid-column: span 2;
        }

        @media (max-width: 500px) {
            grid-column: span 1;
        }
    }

    /** Helper for grid layout children within mat-dialog */
    &.full-dialog {
        gap: 40px;
        margin-top: 40px;

        &.grid-5 {
            .grid-col-span-2 {
                grid-column: span 2;
            }

            .grid-col-span-3 {
                grid-column: span 3;
            }

            @media (max-width: 1500px) {
                grid-template-columns: repeat(5, 1fr);
            }

            @media (max-width: 1236px) {
                grid-template-columns: repeat(5, 1fr);
            }

            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);

                .grid-col-span-2,
                .grid-col-span-3 {
                    grid-column: span 1;
                }
            }
        }
    }
}

dl {
    $dt-grid-width: 7rem;

    &.grid-list {
        display: grid;
        grid-template-columns: $dt-grid-width 1fr;

        dt, dd {
            display: inline-grid;
            line-height: 1;
            padding-bottom: 1.2rem;

            &:last-of-type {
                padding-bottom: 0;
            }
        }

        dt {
            font-family: $fontsemibold;
        }

        &.grid-list-2-col {
            grid-template-columns: repeat(2, 2fr);

            dd {
                margin: 0 0 0 auto;
            }
        }

        &:not(.grid-list-2-col) {
            dd {
                min-width: 6rem;
                padding-left: 1rem;

                + dd {
                    margin-left: $dt-grid-width;
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
            }
        }
    }
}

ngx-material-timepicker-container {
    .timepicker-backdrop-overlay {
        z-index: 1000 !important;
    }

    .timepicker-overlay {
        z-index: 1000 !important;
    }

    .timepicker {
        .timepicker__actions {
            justify-content: space-between !important;
        }
    }
}

input,
select,
textarea {
    color: rgba(61, 78, 95, 0.5);
    font: normal normal 14px $fontreg;

    &.error, &.ng-invalid.ng-touched {
        border: 1px solid #EC413D;
    }
}

lf-geo-region-selector {
    &.ng-invalid.ng-touched select {
        border: 1px solid #EC413D;
    }
}

.list-styled {
    ul {
        list-style: disc inside;
    }

    ol {
        list-style: decimal inside;
    }
}

/** Normalize display of name/email combo across app */
.display-name {
    display: flex;
    line-height: 1.4 !important;
    padding-left: 12px;
    width: 100%;

    > .with-email {
        color: $colordescription;
        display: block;
        font-size: 13px;
        overflow: hidden;
        padding-left: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
}

ngx-toasta #toasta ngx-toast .toast {
    border-radius: 5px;
    box-shadow: 0px 0px 7px #888888;
    color: #FFFFFF;
    font-family: 'ProximaNovaRegular', sans-serif;
    height: auto;
    min-height: 80px;
    width: 345px;
    line-height: 1.2;

    .close-button {
        content: url(/assets/images/payhoa/toasties/close_message_icon.png);
        opacity: 1;
        right: 8px;
        top: 8px;
    }

    .toast-text {
        padding: 15px 15px 15px 60px;

        &::before {
            left: 18px;
            position: absolute;
            top: 15px;
        }

        .toast-title {
            font-size: 16px;
        }

        .toast-msg {
            font-size: 14px;
            line-height: 12px;
        }
    }


    &.toasta-type-success {
        background: #44BA9F url(/assets/images/payhoa/toasties/success_icon_big.png) no-repeat left top;

        .toast-text {

            &::before {
                content: url(/assets/images/payhoa/toasties/success_icon_white.png);

            }

        }
    }

    &.toasta-type-warning {
        background: #EBA447 url(/assets/images/payhoa/toasties/wrong_icon_big.png) no-repeat left top;

        .toast-text {

            &::before {
                content: url(/assets/images/payhoa/toasties/wrong_icon_white.png);

            }

        }
    }

    &.toasta-type-error {
        background: #EB4D4B url(/assets/images/payhoa/toasties/error_icon_big.png) no-repeat left top;

        .toast-text {

            &::before {
                content: url(/assets/images/payhoa/toasties/error_icon_white.png);

            }

        }
    }
}

.mat-drawer-backdrop.mat-drawer-shown,
.cdk-overlay-dark-backdrop {
    background: rgba($coloroverlay, 0.7) !important;
}
