@import 'styles/ui/base/variables';

@mixin svg-stroke-outline($color: $black, $stroke: 1) {
    fill: none;
    stroke: $color;
    stroke-width: $stroke;
    stroke-linejoin: round;
}

// normalize to black in CSS filter conversions
$normalize-filter: brightness(0) saturate(1);

// supported order of filter functions
$filters-list: 'invert' 'sepia' 'saturate' 'hue' 'brightness' 'contrast';

@mixin svg-icon-filter($args...) {
    $functions: $normalize-filter;
    @each $value in $args {
        $i: index($args, $value);
        $filter: nth($filters-list, $i);
        @if($filter == 'hue') {
            $filter: 'hue-rotate';
            $value: $value + 'deg';
        } @else if($value > 0) {
            $value: calc($value / 100);
        }
        $functions: $functions + ' ' + #{$filter + '(' + $value + ')'};
    }
    -webkit-filter: unquote($functions);
    filter: unquote($functions);
}

/** CSS filter conversions from black to target hex color using https://codepen.io/sosuke/pen/Pjoqqp with 0-0.2 loss */

@mixin svg-white-filter() {
    @include svg-icon-filter(100);
}

@mixin svg-black-filter() {
    @include svg-icon-filter(10,1,0,80,90,94);
}

/** status svg colors */
// #0D9472
@mixin svg-green-v2-filter() {
    @include svg-icon-filter(48,99,5325,150,96,90);
}

// #E11D48
@mixin svg-red-v2-filter() {
    @include svg-icon-filter(22,56,6915,337,91,93);
}

/** hover svg colors */
// #2A6BE6
@mixin svg-blue-v2-filter() {
    @include svg-icon-filter(33,74,1175,196,99,104);
}

// #247FDE
@mixin svg-blue-link-filter() {
    @include svg-icon-filter(47,32,4763,192,89,95);
}

// #2895F1
@mixin svg-blue-filter() {
    @include svg-icon-filter(51,66,2931,185,97,94);
}

// #0069d9
@mixin svg-dark-blue-filter() {
    @include svg-icon-filter(43,86,6381,200,91,101);
}

// #94C9FF
@mixin svg-nav-icon-light-blue-filter() {
    @include svg-icon-filter(72,7,2676,180,105,102);
}

// #2895F1
@mixin svg-nav-icon-blue-filter() {
    @include svg-icon-filter(51,97,3120,188,103,89);
}

// #0563C1
@mixin svg-nav-icon-dark-blue-filter() {
    @include svg-icon-filter(20,99,2158,198,101,96);
}

// #007BFF
@mixin svg-mat-icon-dark-blue-filter() {
    @include svg-icon-filter(35,36,5816,198,99,105);
}

/** hover action svg colors */

@mixin svg-edit-blue-filter($darken: false) {
    @if $darken {
        // #1C6BBD
        @include svg-icon-filter(25,98,3689,200,90,78);
    } @else {
        // #247FDE
        @include svg-blue-link-filter();
    }
}

@mixin svg-close-red-filter($darken: false) {
    @if $darken {
        // #BF3F3F
        @include svg-icon-filter(30,55,1868,332,91,87);
    } @else {
        // #EB5858
        @include svg-icon-filter(42,60,3396,333,109,85);
    }
}

// #FF7700
@mixin svg-warning-yellow-filter() {
    @include svg-icon-filter(72,56,7284,2,105,104);
}

/** rating stars */

// #FCCF42
@mixin svg-gold-filter() {
    @include svg-icon-filter(92,52,7481,321,100,98);
}

// #F6F6F8
@mixin svg-lightest-gray-filter() {
    @include svg-icon-filter(96,13,18,189,99,98);
}

// #8796A9
@mixin svg-placeholder-text-v2-filter() {
    @include svg-icon-filter(68,8,833,174,87,85);
}

// #4D5A6E
@mixin svg-text-alternate-v2-filter() {
    @include svg-icon-filter(35,8,1320,177,94,91);
}

/** empty state svg colors */

// #E3E8ED
@mixin svg-light-gray-filter() {
    @include svg-icon-filter(97,3,438,178,94,99);
}

// #DFE6EE
@mixin svg-gray-200-filter() {
    @include svg-icon-filter(97,1,4035,180,96,94);
}

// #CED4DA
@mixin svg-gray-400-filter() {
    @include svg-icon-filter(96,5,369,178,91,87);
}

/** mat-icon button svg colors */

// #ADB5BD
@mixin svg-gray-500-filter() {
    @include svg-icon-filter(78,13,181,169,91,89);
}

// #868E96
@mixin svg-gray-600-filter() {
    @include svg-icon-filter(62,13,220,169,87,90);
}

// #495057
@mixin svg-gray-700-filter() {
    @include svg-icon-filter(29,5,1065,169,93,84);
}

// #313541
@mixin svg-dark-gray-filter() {
    @include svg-icon-filter(24,4,3457,171,96,76);
}

/** toolbar svg icons here for now */
@mixin svg-icons-blue-filter() {
    opacity: 1;

    #icon_new_invoices,
    #icon_record_payments {
        rect {
            &:first-of-type {
                @include svg-blue-filter();
            }

            &:last-of-type {
                @include svg-dark-blue-filter();
            }
        }
    }

    #icon_add,
    #icon_unit,
    #icon_text_message,
    #icon_email_message,
    #icon_tag {
        path {
            &:first-of-type {
                @include svg-blue-filter();
            }

            &:last-of-type {
                @include svg-dark-blue-filter();
            }
        }
    }

    #icon_unit {
        #icon_unit_step {
            @include svg-blue-filter();
        }
    }

    #delete_image_icon,
    #icon_other_actions {
        circle {
            @include svg-blue-filter();

            &:first-of-type,
            &:last-of-type {
                @include svg-dark-blue-filter();
            }
        }
    }

    #icon_user {
        circle {
            @include svg-blue-filter();
        }

        ellipse {
            @include svg-dark-blue-filter();
        }
    }
}

/** navbar svg icons here for now */
@mixin svg-icons-navbar-filter() {
    #icon_settings {
        path {
            @include svg-gray-500-filter();
        }
    }

    #icon_user {
        circle,
        ellipse {
            @include svg-gray-500-filter();
        }
    }
}

@mixin svg-icons-navbar-blue-filter() {
    #icon_settings {
        path {
            @include svg-blue-filter();
        }
    }

    #icon_arrow {
        path {
            @include svg-blue-filter();
        }
    }

    #icon_user {
        circle {
            @include svg-blue-filter();
        }

        ellipse {
            @include svg-dark-blue-filter();
        }
    }
}

@mixin svg-icons-sidenav-filter() {
    .hover-light-blue {
        @include svg-nav-icon-light-blue-filter();
    }

    .hover-blue {
        @include svg-nav-icon-blue-filter();
    }

    .hover-dark-blue {
        @include svg-nav-icon-dark-blue-filter();
    }

    .hover-white {
        @include svg-white-filter();
    }
}

/** empty svg icons here for now */
@mixin svg-icons-gray-filter() {
    #icon_unit,
    #icon_new_invoices,
    #icon_record_payments,
    #icon_vendors,
    #icon_reports {
        path,
        rect {
            &:first-of-type {
                @include svg-light-gray-filter();
            }

            &:last-of-type {
                @include svg-gray-400-filter();
            }
        }
    }

    #icon_budget {
        circle {
            @include svg-gray-400-filter();
        }
        path {
            @include svg-light-gray-filter();
        }
    }

    #icon_transaction {
        #icon_transaction_top {
            path,
            rect {
                @include svg-gray-400-filter();
            }
        }
        #icon_transaction_bottom {
            path,
            rect {
                @include svg-light-gray-filter();
            }
        }
    }

    #icon_builder {
        #icon_builder_top,
        #icon_builder_left {
            @include svg-gray-400-filter();
        }

        #icon_builder_right {
            @include svg-light-gray-filter();
        }
    }

    #icon_unit {
        #icon_unit_step {
            @include svg-light-gray-filter();
        }
    }

    #icon_hidden,
    #icon_requests {
        path {
            @include svg-light-gray-filter();
        }
    }
}

/** app button svg icons here for now */
@mixin svg-icons-dark-gray-filter() {
    #icon_unit {
        path {
            @include svg-gray-600-filter();

            &:last-of-type {
                @include svg-dark-gray-filter();
            }
        }
    }

    #icon_hidden,
    #icon_requests {
        path {
            @include svg-dark-gray-filter();
        }
    }

    #icon_user {
        path {
            @include svg-white-filter();
        }

        circle,
        ellipse {
            @include svg-dark-gray-filter();
        }
    }
}

/** image row svg icons for edit and delete */
@mixin image-row-svg-icons-filter($darken: false) {
    #edit_image_icon {
        circle {
            @include svg-edit-blue-filter($darken);
        }
    }

    #delete_image_icon {
        circle {
            @include svg-close-red-filter($darken);
        }
    }
}

/** normalize svg icons to btn size and color */
@mixin svg-icons-button() {
    .btn-with-mat-icon {
        &.btn-primary,
        &.btn-secondary {
            mat-icon {
                svg {
                    rect,
                    path {
                        @include svg-white-filter();
                    }
                }
            }
        }

        &.btn-link {
            mat-icon {
                svg {
                    rect,
                    path {
                        @include svg-blue-link-filter();
                    }
                }
            }

            &:hover {
                mat-icon {
                    svg {
                        rect,
                        path {
                            @include svg-dark-blue-filter();
                        }
                    }
                }
            }
        }

        &.btn-outline-secondary {
            mat-icon {
                svg {
                    rect,
                    path {
                        @include svg-dark-gray-filter();
                    }
                }

                @include svg-icons-dark-gray-filter();
            }
        }
    }

    .drag-and-drop {
        mat-icon.drag-and-drop-image {
            @include svg-icons-gray-filter();
            @include svg-icons-large();
        }
    }
}

/** normalize mat-icon color */
@mixin svg-mat-icon-default() {
    mat-icon {
        svg {
            rect,
            path {
                @include svg-dark-gray-filter();
            }
        }
    }
}

@mixin svg-mat-icon-hover() {
    mat-icon {
        svg {
            rect,
            path {
                @include svg-dark-blue-filter();
            }
        }
    }
}

@mixin svg-mat-icon-placeholder() {
    mat-icon {
        svg {
            rect,
            path {
                @include svg-placeholder-text-v2-filter();
            }
        }
    }
}

/** transform to "large" icon for jumbotrons/empty display */
@mixin svg-icons-large() {
    height: 126px;
    width: 96px;
}

/** transform to "smaller" icon for list and table displays */
@mixin svg-icons-table() {
    position: absolute;
    width: 14px;
    top: -2px;
}

/** override to adjust for "add" icon */
@mixin svg-add-icon() {
    position: relative;
    width: 11px;
}

/** override to handle base icon in mat-menu */
@mixin svg-mat-menu-icon() {
    @include svg-black-filter();
    opacity: 0.87;
}

/** override to handle mat-icons in app sidenav */
@mixin svg-icons-sidenav() {
    mat-icon {
        margin-right: 0.35rem;
        svg {
            > g {
                transform: translate(38px, 23px) !important;

                #icon_settings_cog {
                    transform: translate(-38px, -32px) !important;
                }
            }
        }
    }

    &:hover, &:focus, &:active, &.mat-button-active {
        mat-icon {
            svg {
                @include svg-icons-sidenav-filter();
            }
        }
    }

    &:hover, &:focus {
        mat-icon {
            svg {
                opacity: 0.8 !important;
            }
        }
    }
}
