@import 'styles/ui/base/variables';
@import 'styles/ui/mixins/svg';

@include svg-icons-button();

mat-icon {
    &.scale-smaller {
        transform: scale(0.65) translateX(1px) translateY(-1px);
    }

    &.scale-small {
        transform: scale(0.85) translateX(1px) translateY(-1px);
    }

    &.scale-2x {
        transform: scale(2);
    }

    &.disabled {
        color: #F3F3F3;
    }
}

/** TODO: start collecting material overrides here for consolidation */
.mat-button-group {
    display: flex;

    .mat-button,
    .mat-raised-button {
        border-radius: 0;
        margin-left: -1px;
        position: relative;

        &:first-child {
            margin-left: 0;
        }

        &.rounded-left {
            border-radius: 0.25rem 0 0 0.25rem;
            margin-right: 0 !important;
        }

        &.rounded-right {
            border-radius: 0 0.25rem 0.25rem 0;
        }

        &:hover, &:focus {
            index: 1;
        }
    }

    &.mat-switch {
        app-button {
            width: 50%;
        }

        button, a {
            height: 140px;
            width: 100%;

            .button-text {
                white-space: normal;
            }
        }
    }
}

.mat-select-panel,
.mat-autocomplete-panel {
    font-size: 13px;

    .mat-option,
    .mat-option-text {
        font-size: inherit;
    }

    .mat-optgroup-label,
    .mat-option-text {
        font-family: $fontreg;
    }
}

.mat-select-panel.single-select-panel {
    padding: 0.5em 0;

    .mat-option {
        font-family: $fontreg;
        font-size: 14px;
        height: 36px !important;
        line-height: 36px !important;
    }
}

mat-select-trigger,
mat-option {
    .text-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .count {
        color: #1871DD;
        min-width: 2.2rem;
    }
}

mat-select-trigger {
    .text-ellipsis {
        max-width: 132px;
    }
}

.mat-select-value-text {
    font-family: $fontreg;
    color: #6C757D !important;
}

.mat-select-width-auto {
    .mat-form-field-infix {
        width: auto !important;
    }
}

mat-option {
    .text-ellipsis {
        max-width: 200px;
    }

    &.wide {
        .text-ellipsis {
            max-width: 320px;
        }
    }
}

.mat-optgroup-label {
    color: $text-muted;
}

.mat-select-with-count .mat-option-text,
.mat-select-with-count mat-select-trigger {
    display: flex;
    justify-content: space-between;

    .count {
        text-align: center;
    }
}

.mat-select-panel {
    &.mat-multiselect {
        .mat-optgroup {
            width: 100%;

            .mat-checkbox {
                width: 100%;

                .mat-checkbox-layout {
                    padding-left: 16px;
                    padding-right: 16px;
                    width: 100%;
                }

                .mat-checkbox-inner-container {
                    margin-left: 0;
                }

                .mat-checkbox-label {
                    color: $text-muted;
                    font-size: 13px;
                }

                .mat-checkbox-frame {
                    border-color: #D0D6DD;
                    border-width: 2px !important;
                }
            }

            .mat-optgroup-label {
                border-top: 1px solid #D0D6DD;
                padding: 0;
                position: relative;

                .mat-optgroup-toggle {
                    position: absolute;
                    right: 1rem;
                    top: 0.35rem;
                }
            }

            > .mat-option {
                padding-left: 24px;
            }

            .mat-option-with-toggle {
                span {
                    overflow-x: auto;
                    text-overflow: ellipsis;
                    width: 100%;
                }
            }

            .mat-child-option {
                &:before {
                    content: '--';
                    padding-right: 0.35rem;
                }
            }

            &:first-of-type {
                .mat-optgroup-label {
                    border-top: 0;
                }
            }
        }
    }
}

.mat-datepicker-container {
    min-width: 14rem;
}

input.mat-datepicker-input {
    // match lf-datepicker's max width for bare datepickers
    max-width: 120px;

    // prepend the calendar icon (remove this if we ever move to mat-inputs, see material docs for composition approach)
    background: white url(/assets/images/payhoa/other/icon_calendar.svg) no-repeat;

    // position the icon 5px from the left edge of the input
    background-position: 5px center;

    // pad the input to make room for the icon ((5px icon padding x 2) + 13px icon width)
    padding-left: 23px;
}

.form-group input.mat-datepicker-input {
    // allow datepicker to fill container if it's a child of a form-group (assume the dev is laying out the form with particular spacing)
    max-width: unset;
}

// override mat-datepicker's calendar to match lf-datepicker
mat-calendar {
    .mat-calendar-header {
        background: #E4EBF3;
        padding: 5px 0;

        .mat-calendar-controls {
            margin: 0;

            // override positioning on header controls to attain the same layout as lf-datepicker

            // center the month/year button
            .mat-calendar-period-button {
                margin: 0 auto;
            }

            // hide the existing spacer element
            .mat-calendar-spacer {
                display: none;
            }

            // move previous and next buttons to the edges of the frame
            .mat-calendar-previous-button {
                position: absolute;
                left: 0;
            }

            .mat-calendar-next-button {
                position: absolute;
                right: 0;
            }
        }
    }
}

.mat-menu-container {
    display: flex;
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
    position: absolute;
    z-index: 1000;

    .mat-menu-panel {
        background: $white;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        border-radius: 4px;
        max-height: calc(100vh - 48px);
        max-width: 400px;
        min-width: 360px;

        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.mat-expansion-panel {
    box-shadow: $box-shadow !important;

    .mat-expansion-panel-header {
        > * {
            align-self: center;
            display: flex;
        }

        .mat-expansion-panel-header-title {
            color: #34495E;
            font-family: $fontsemibold;
            font-size: 15px;
        }

        .mat-expansion-indicator {
            &:after {
                color: $input-color;
                margin-top: -3px;
            }
        }
    }

    &.p-0 {
        .mat-expansion-panel-body {
            padding: 0;
        }
    }

    &.mat-expanded {
        .mat-expansion-panel-header {
            .mat-expansion-indicator {
                &:after {
                    margin-top: -4px;
                }
            }
        }
    }
}

.card-body {
    .mat-expansion-panel {
        box-shadow: none !important;
    }
}
