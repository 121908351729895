/*!

 * FullCalendar v3.4.0 Stylesheet
 * Docs & License: https://fullcalendar.io/;
 * (c) 2017 Adam Shaw
 */
.fc-icon, body .fc {
    font-size: 1em;
}

.fc-button-group, .fc-icon {
    display: inline-block;
}

.fc-bg, .fc-row .fc-bgevent-skeleton, .fc-row .fc-highlight-skeleton {
    bottom: 0;
}

.fc-icon, .fc-unselectable {
    -webkit-touch-callout: none;
    -khtml-user-select: none;
}

.fc {
    direction: ltr;
    text-align: left;
}

.fc-rtl {
    text-align: right;
}

.fc th, .fc-basic-view td.fc-week-number, .fc-icon, .fc-toolbar {
    text-align: center;
}

.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead {
    border-color: #DFE6EE;
}

.fc-unthemed .fc-popover {
    background-color: #FFFFFF;
}

.fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-popover .fc-header {
    background: #EEEEEE;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
    color: #666666;
}

.fc-unthemed td.fc-today {
    //background: #fcf8e3;
}

.fc-highlight {
    background: #BCE8F1;
    opacity: .3;
}

.fc-bgevent {
    background: #8FDF82;
    opacity: .3;
}

.fc-nonbusiness {
    background: #D7D7D7;
}

.fc-unthemed .fc-disabled-day {
    background: #D7D7D7;
    opacity: .3;
}

.ui-widget .fc-disabled-day {
    background-image: none;
}

.fc-icon {
    font-family: 'Courier New', Courier, monospace;
    height: 1em;
    line-height: 1em;
    overflow: hidden;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.fc-icon:after {
    position: relative;
}

.fc-icon-left-single-arrow:after {
    content: '\02039';
    font-size: 200%;
    font-weight: 700;
    top: -7%;
}

.fc-icon-right-single-arrow:after {
    content: '\0203A';
    font-size: 200%;
    font-weight: 700;
    top: -7%;
}

.fc-icon-left-double-arrow:after {
    content: '\000AB';
    font-size: 160%;
    top: -7%;
}

.fc-icon-right-double-arrow:after {
    content: '\000BB';
    font-size: 160%;
    top: -7%;
}

.fc-icon-left-triangle:after {
    content: '\25C4';
    font-size: 125%;
    top: 3%;
}

.fc-icon-right-triangle:after {
    content: '\25BA';
    font-size: 125%;
    top: 3%;
}

.fc-icon-down-triangle:after {
    content: '\25BC';
    font-size: 125%;
    top: 2%;
}

.fc-icon-x:after {
    content: '\000D7';
    font-size: 200%;
    top: 6%;
}

.fc button {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 1em;
    height: 2.1em;
    margin: 0;
    padding: 0 .6em;
    white-space: nowrap;
}

.fc button::-moz-focus-inner {
    margin: 0;
    padding: 0;
}

.fc-state-default {
    background-color: #F5F5F5;
    background-image: -moz-linear-gradient(top, #FFFFFF, #E6E6E6);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#FFFFFF), to(#E6E6E6));
    background-image: -webkit-linear-gradient(top, #FFFFFF, #E6E6E6);
    background-image: -o-linear-gradient(top, #FFFFFF, #E6E6E6);
    background-image: linear-gradient(to bottom, #FFFFFF, #E6E6E6);
    background-repeat: repeat-x;
    border: 1px solid;
    border-color: #E6E6E6 #E6E6E6 #BFBFBF;
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
    color: #333333;
    text-shadow: 0 1px 1px rgba(255, 255, 255, .75);
}

.fc-state-default.fc-corner-left {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.fc-state-default.fc-corner-right {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.fc button .fc-icon {
    margin: 0 .2em;
    position: relative;
    top: -.05em;
    vertical-align: middle;
}

.fc-state-active, .fc-state-disabled, .fc-state-down, .fc-state-hover {
    background-color: #E6E6E6;
    color: #333333;
}

.fc-state-hover {
    background-position: 0 -15px;
    color: #333333;
    text-decoration: none;
    -moz-transition: background-position .1s linear;
    -o-transition: background-position .1s linear;
    -webkit-transition: background-position .1s linear;
    transition: background-position .1s linear;
}

.fc-state-active, .fc-state-down {
    background-color: #CCCCCC;
    background-image: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, .15), 0 1px 2px rgba(0, 0, 0, .05);
}

.fc-state-disabled {
    background-image: none;
    box-shadow: none;
    cursor: default;
    opacity: .65;
}

.fc-event.fc-draggable, .fc-event[href], .fc-popover .fc-header .fc-close, a[data-goto] {
    cursor: pointer;
}

.fc .fc-button-group > * {
    border: 1px solid #E4EBF3;
    float: left;
    margin: 0 0 0 -1px;
}

.fc .fc-button-group > :first-child {
    margin-left: 0;
}

.fc-popover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, .15);
    position: absolute;
}

.fc-popover .fc-header {
    padding: 2px 4px;
}

.fc-popover .fc-header .fc-title {
    margin: 0 2px;
}

.fc-ltr .fc-popover .fc-header .fc-title, .fc-rtl .fc-popover .fc-header .fc-close {
    float: left;
}

.fc-ltr .fc-popover .fc-header .fc-close, .fc-rtl .fc-popover .fc-header .fc-title {
    float: right;
}

.fc-unthemed .fc-popover {
    border-style: solid;
    border-width: 1px;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
    font-size: .9em;
    margin-top: 2px;
}

.fc-popover > .ui-widget-header + .ui-widget-content {
    border-top: 0;
}

.fc-divider {
    border-style: solid;
    border-width: 1px;
}

hr.fc-divider {
    border-width: 1px 0;
    height: 0;
    margin: 0;
    padding: 0 0 2px;
}

.fc-bg table, .fc-row .fc-bgevent-skeleton table, .fc-row .fc-highlight-skeleton table {
    height: 100%;
}

.fc-clear {
    clear: both;
}

.fc-bg, .fc-bgevent-skeleton, .fc-helper-skeleton, .fc-highlight-skeleton {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fc table {
    border-collapse: collapse;
    border-spacing: 0;
    box-sizing: border-box;
    font-size: 1em;
    table-layout: fixed;
    width: 100%;
}

.fc td, .fc th {
    border-style: solid;
    border-width: 1px;
    padding: 0;
    vertical-align: top;
}

.fc td.fc-today .fc-day-number {
    background: #E94E4E;
    border-radius: 50%;
    border-style: double;
    color: white;
    line-height: 1;
    margin: 3px;
    padding: 4px;
}

a[data-goto]:hover {
    text-decoration: underline;
}

.fc .fc-row {
    border-style: solid;
    border-width: 0;
}

.fc-row table {
    border-bottom: 0 hidden transparent;
    border-left: 0 hidden transparent;
    border-right: 0 hidden transparent;
}

.fc-row:first-child table {
    border-top: 0 hidden transparent;
}

.fc-row {
    position: relative;
}

.fc-row .fc-bg {
    border-bottom: 1px solid #DAE2EC;
    z-index: 1;
}

.fc-row .fc-bgevent-skeleton td, .fc-row .fc-highlight-skeleton td {
    border-color: transparent;
}

.fc-row .fc-bgevent-skeleton {
    z-index: 2;
}

.fc-row .fc-highlight-skeleton {
    z-index: 3;
}

.fc-row .fc-content-skeleton {
    padding-bottom: 2px;
    position: relative;
    z-index: 4;
}

.fc-row .fc-helper-skeleton {
    z-index: 5;
}

.fc-row .fc-content-skeleton td, .fc-row .fc-helper-skeleton td {
    background: 0 0;
    border-bottom: 0;
    border-color: transparent;
    border-right: 1px solid #DAE2EC;
}

.fc-row .fc-content-skeleton tbody td, .fc-row .fc-helper-skeleton tbody td {
    border-top: 0;
}

.fc-scroller {
    -webkit-overflow-scrolling: touch;
}

.fc-row.fc-rigid, .fc-time-grid-event {
    overflow: hidden;
}

.fc-scroller > .fc-day-grid, .fc-scroller > .fc-time-grid {
    position: relative;
    width: 100%;
}

.fc-event {
    border: 1px solid #3A87AD;
    border-radius: 3px;
    display: block;
    font-size: .85em;
    font-weight: 400;
    line-height: 1.3;
    position: relative;
}

.fc-event, .fc-event-dot {
    background-color: #3A87AD;
}

.fc-event, .fc-event:hover, .ui-widget .fc-event {
    color: #FFFFFF;
    text-decoration: none;
}

.fc-not-allowed, .fc-not-allowed .fc-event {
    cursor: not-allowed;
}

.fc-event .fc-bg {
    background: #FFFFFF;
    opacity: .25;
    z-index: 1;
}

.fc-event .fc-content {
    position: relative;
    z-index: 2;
}

.fc-event .fc-resizer {
    display: none;
    position: absolute;
    z-index: 4;
}

.fc-event.fc-allow-mouse-resize .fc-resizer, .fc-event.fc-selected .fc-resizer {
    display: block;
}

.fc-event.fc-selected .fc-resizer:before {
    content: '';
    height: 40px;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    width: 40px;
    z-index: 9999;
}

.fc-event.fc-selected {
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
    z-index: 9999 !important;
}

.fc-event.fc-selected.fc-dragging {
    box-shadow: 0 2px 7px rgba(0, 0, 0, .3);
}

.fc-h-event.fc-selected:before {
    bottom: -10px;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: -10px;
    z-index: 3;
}

.fc-ltr .fc-h-event.fc-not-start, .fc-rtl .fc-h-event.fc-not-end {
    border-bottom-left-radius: 0;
    border-left-width: 0;
    border-top-left-radius: 0;
    margin-left: 0;
    padding-left: 1px;
}

.fc-ltr .fc-h-event.fc-not-end, .fc-rtl .fc-h-event.fc-not-start {
    border-bottom-right-radius: 0;
    border-right-width: 0;
    border-top-right-radius: 0;
    margin-right: 0;
    padding-right: 1px;
}

.fc-ltr .fc-h-event .fc-start-resizer, .fc-rtl .fc-h-event .fc-end-resizer {
    cursor: w-resize;
    left: -1px;
}

.fc-ltr .fc-h-event .fc-end-resizer, .fc-rtl .fc-h-event .fc-start-resizer {
    cursor: e-resize;
    right: -1px;
}

.fc-h-event.fc-allow-mouse-resize .fc-resizer {
    bottom: -1px;
    top: -1px;
    width: 7px;
}

.fc-h-event.fc-selected .fc-resizer {
    background: #FFFFFF;
    border-color: inherit;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 6px;
    margin-top: -4px;
    top: 50%;
    width: 6px;
}

.fc-ltr .fc-h-event.fc-selected .fc-start-resizer, .fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
    margin-left: -4px;
}

.fc-ltr .fc-h-event.fc-selected .fc-end-resizer, .fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
    margin-right: -4px;
}

.fc-day-grid-event {
    margin: 1px 2px 0;
    padding: 0 1px;
}

tr:first-child > td > .fc-day-grid-event {
    margin-top: 2px;
}

.fc-day-grid-event.fc-selected:after {
    background: #000000;
    bottom: -1px;
    content: '';
    left: -1px;
    opacity: .25;
    position: absolute;
    right: -1px;
    top: -1px;
    z-index: 1;
}

.fc-day-grid-event .fc-content {
    overflow: hidden;
    white-space: nowrap;
}

.fc-day-grid-event .fc-time {
    margin-right: 10px;
}

.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer, .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
    margin-left: -2px;
}

.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer, .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
    margin-right: -2px;
}

a.fc-more {
    cursor: pointer;
    font-size: .85em;
    margin: 1px 3px;
    text-decoration: none;
}

a.fc-more:hover {
    text-decoration: underline;
}

.fc-limited {
    display: none;
}

.fc-day-grid .fc-row {
    z-index: 1;
}

.fc-more-popover {
    width: 220px;
    z-index: 2;
}

.fc-more-popover .fc-event-container {
    padding: 10px;
}

.fc-now-indicator {
    border: 0 solid red;
    position: absolute;
}

.fc-unselectable {
    -webkit-tap-highlight-color: transparent;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1em;
}

.fc-toolbar.fc-footer-toolbar {
    margin-top: 1em;
}

.fc-toolbar .fc-left {
    float: left;
}

.fc-toolbar .fc-right {
    float: right;
}

.fc-toolbar .fc-center {
    display: inline-block;
}

.fc .fc-toolbar > * > * {
    float: left;
    margin-left: .75em;
}

.fc .fc-toolbar > * > :first-child {
    margin-left: 0;
}

.fc-toolbar h2 {
    font-size: 24px;
    margin: 0;

    @media (max-width: 600px) {;
        font-size: 20px;
        margin-top: 30px !important;
    }
}

.fc-toolbar button {
    position: relative;
}

.fc-toolbar .fc-state-hover, .fc-toolbar .ui-state-hover {
    z-index: 2;
}

.fc-toolbar .fc-state-down {
    z-index: 3;
}

.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active {
    z-index: 4;
}

.fc-toolbar button:focus {
    z-index: 5;
}

.fc-view-container *, .fc-view-container :after, .fc-view-container :before {
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.fc-view, .fc-view > table {
    position: relative;
    z-index: 1;
}

.fc-basicDay-view .fc-content-skeleton, .fc-basicWeek-view .fc-content-skeleton {
    padding-bottom: 1em;
}

.fc-basic-view .fc-body .fc-row {
    min-height: 4em;
}

.fc-row.fc-rigid .fc-content-skeleton {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fc-day-top.fc-other-month {
    opacity: .3;
}

.fc-basic-view .fc-day-number, .fc-basic-view .fc-week-number {
    padding: 2px;
}

.fc-basic-view th.fc-day-number, .fc-basic-view th.fc-week-number {
    padding: 0 2px;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
    float: right;
    font-weight: 600;
}

.fc-rtl .fc-basic-view .fc-day-top .fc-day-number {
    float: left;
    font-weight: 600;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-week-number {
    border-radius: 0 0 3px;
    float: left;
}

.fc-rtl .fc-basic-view .fc-day-top .fc-week-number {
    border-radius: 0 0 0 3px;
    float: right;
}

.fc-basic-view .fc-day-top .fc-week-number {
    background-color: #F2F2F2;
    color: grey;
    min-width: 1.5em;
    text-align: center;
}

.fc-basic-view td.fc-week-number > * {
    display: inline-block;
    min-width: 1.25em;
}

.fc-agenda-view .fc-day-grid {
    position: relative;
    z-index: 2;
}

.fc-agenda-view .fc-day-grid .fc-row {
    min-height: 3em;
}

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
    padding-bottom: 1em;
}

.fc .fc-axis {
    padding: 0 4px;
    vertical-align: middle;
    white-space: nowrap;
}

.fc-ltr .fc-axis {
    text-align: right;
}

.fc-rtl .fc-axis {
    text-align: left;
}

.ui-widget td.fc-axis {
    font-weight: 400;
}

.fc-time-grid, .fc-time-grid-container {
    position: relative;
    z-index: 1;
}

.fc-time-grid {
    min-height: 100%;
}

.fc-time-grid table {
    border: 0 hidden transparent;
}

.fc-time-grid > .fc-bg {
    z-index: 1;
}

.fc-time-grid .fc-slats, .fc-time-grid > hr {
    position: relative;
    z-index: 2;
}

.fc-time-grid .fc-content-col {
    position: relative;
}

.fc-time-grid .fc-content-skeleton {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
}

.fc-time-grid .fc-business-container {
    position: relative;
    z-index: 1;
}

.fc-time-grid .fc-bgevent-container {
    position: relative;
    z-index: 2;
}

.fc-time-grid .fc-highlight-container {
    position: relative;
    z-index: 3;
}

.fc-time-grid .fc-event-container {
    position: relative;
    z-index: 4;
}

.fc-time-grid .fc-now-indicator-line {
    z-index: 5;
}

.fc-time-grid .fc-helper-container {
    position: relative;
    z-index: 6;
}

.fc-time-grid .fc-slats td {
    border-bottom: 0;
    height: 1.5em;
}

.fc-time-grid .fc-slats .fc-minor td {
    border-top-style: dotted;
}

.fc-time-grid .fc-slats .ui-widget-content {
    background: 0 0;
}

.fc-time-grid .fc-highlight {
    left: 0;
    position: absolute;
    right: 0;
}

.fc-ltr .fc-time-grid .fc-event-container {
    margin: 0 2.5% 0 2px;
}

.fc-rtl .fc-time-grid .fc-event-container {
    margin: 0 2px 0 2.5%;
}

.fc-time-grid .fc-bgevent, .fc-time-grid .fc-event {
    position: absolute;
    z-index: 1;
}

.fc-time-grid .fc-bgevent {
    left: 0;
    right: 0;
}

.fc-v-event.fc-not-start {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-width: 0;
    padding-top: 1px;
}

.fc-v-event.fc-not-end {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-width: 0;
    padding-bottom: 1px;
}

.fc-time-grid-event.fc-selected {
    overflow: visible;
}

.fc-time-grid-event.fc-selected .fc-bg {
    display: none;
}

.fc-time-grid-event .fc-content {
    overflow: hidden;
}

.fc-time-grid-event .fc-time, .fc-time-grid-event .fc-title {
    padding: 0 1px;
}

.fc-time-grid-event .fc-time {
    font-size: .85em;
    margin-right: 20px;
    white-space: nowrap;
}

.fc-time-grid-event.fc-short .fc-content {
    white-space: nowrap;
}

.fc-time-grid-event.fc-short .fc-time, .fc-time-grid-event.fc-short .fc-title {
    display: inline-block;
    vertical-align: top;
}

.fc-time-grid-event.fc-short .fc-time span {
    display: none;
}

.fc-time-grid-event.fc-short .fc-time:before {
    content: attr(data-start);
}

.fc-time-grid-event.fc-short .fc-time:after {
    content: '\000A0-\000A0';
}

.fc-time-grid-event.fc-short .fc-title {
    font-size: .85em;
    padding: 0;
}

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
    bottom: 0;
    cursor: s-resize;
    font-family: monospace;
    font-size: 11px;
    height: 8px;
    left: 0;
    line-height: 8px;
    overflow: hidden;
    right: 0;
    text-align: center;
}

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
    content: '=';
}

.fc-time-grid-event.fc-selected .fc-resizer {
    background: #FFFFFF;
    border-color: inherit;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    bottom: -5px;
    height: 8px;
    left: 50%;
    margin-left: -5px;
    width: 8px;
}

.fc-time-grid .fc-now-indicator-line {
    border-top-width: 1px;
    left: 0;
    right: 0;
}

.fc-time-grid .fc-now-indicator-arrow {
    margin-top: -5px;
}

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-width: 5px 0 5px 6px;
    left: 0;
}

.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-width: 5px 6px 5px 0;
    right: 0;
}

.fc-event-dot {
    border-radius: 5px;
    display: inline-block;
    height: 10px;
    width: 10px;
}

.fc-rtl .fc-list-view {
    direction: rtl;
}

.fc-list-view {
    border-style: solid;
    border-width: 1px;
}

.fc .fc-list-table {
    table-layout: auto;
}

.fc-list-table td {
    border-width: 1px 0 0;
    padding: 8px 14px;
}

.fc-list-table tr:first-child td {
    border-top-width: 0;
}

.fc-list-heading {
    border-bottom-width: 1px;
}

.fc-list-heading td {
    font-weight: 700;
}

.fc-ltr .fc-list-heading-main {
    float: left;
}

.fc-ltr .fc-list-heading-alt, .fc-rtl .fc-list-heading-main {
    float: right;
}

.fc-rtl .fc-list-heading-alt {
    float: left;
}

.fc-list-item.fc-has-url {
    cursor: pointer;
}

.fc-list-item:hover td {
    background-color: #F5F5F5;
}

.fc-list-item-marker, .fc-list-item-time {
    white-space: nowrap;
    width: 1px;
}

.fc-ltr .fc-list-item-marker {
    padding-right: 0;
}

.fc-rtl .fc-list-item-marker {
    padding-left: 0;
}

.fc-list-item-title a {
    color: inherit;
    text-decoration: none;
}

.fc-list-item-title a[href]:hover {
    text-decoration: underline;
}

.fc-list-empty-wrap2 {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fc-list-empty-wrap1 {
    display: table;
    height: 100%;
    width: 100%;
}

.fc-list-empty {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.fc-unthemed .fc-list-empty {
    background-color: #EEEEEE;
}

.lf-full-calendar {
    background-color: #FFFFFF;
    border-radius: 6px;
}

.fc-state-highlight > .fc-day-number {
    border-radius: 50%;
    margin: 4px;
}

.fc-toolbar.fc-header-toolbar {
    background-color: #FFFFFF;
    border-radius: 6px 6px 0 0;
    margin-bottom: 1em;
    padding: 8px 6px;
    white-space: nowrap;
}

.fc-toolbar .fc-left, .fc-toolbar .fc-right, .fc-toolbar .fc-center {
    padding-top: 15px;
}

.fc-toolbar .fc-left {
    padding-left: 10px;
}

.fc-toolbar .fc-right {
    padding-right: 10px;
}

.fc-toolbar h2 {
    color: #575757;
    margin: 0;
    white-space: nowrap;
}

.fc-next-button, .fc-prev-button {
    background: white;
    border: 1px solid #C2C2C2;
    box-shadow: none;
    color: #575757;
    text-shadow: none;
}

.fc button.fc-next-button,
.fc button.fc-prev-button {
    padding: 0 2px;
}

.fc-next-button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.fc-prev-button {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.fc-today-button {
    background-color: #FFFFFF;
    background-image: none;
    border-bottom: 1px solid #C2C2C2;
    border-top: 1px solid #C2C2C2;
}

.fc-today-button, fc-state-disabled.fc-button:hover {
    box-shadow: none !important;
    text-shadow: none !important;
}

.fc-state-default.fc-button {
    &:hover {
        background-color: #C2C2C2;
    }
}

.fc-state-disabled.fc-button {
    background-color: #FFFFFF;
    border: 1px solid #E4EBF3;
    box-shadow: none !important;
    text-shadow: none !important;
}

.fc-icon-left-single-arrow, .fc-icon-right-single-arrow {
    font-size: 12px;
}

// header stuff

.fc-day-header:first-child {
    border-left: none;
}

.fc-day-header:last-child {
    border-right: none;
}

.fc-day-header {
    background-color: #EFF2F5;
    color: #8E9BA8;
    font-family: 'ProximaNovaSemibold';
    text-transform: none;
}

.fc-basic-view .fc-day-number, .fc-basic-view .fc-week-number {
    margin: 4px;
}

// week header
.fc-head-container tr {
    height: 30px;

    .fc-day-header {
        vertical-align: middle;
    }
}

.fc-title {
    font-weight: 600;
    text-align: right;
}

.fc-content {
    display: flex;
    font-family: 'Proxima Nova', 'Arial', sans-serif;
    font-size: .8rem;
    justify-content: space-between;
    letter-spacing: 0.025rem;
    padding: 3px;
}

.fc-icon-button {
    background: none;
    border: none;
    box-shadow: 0 1px 2px #C3C3C3;
    color: #575757;

    &:before {
        color: #575757;
        content: '\F021';
        font-family: FontAwesome;
        padding-right: 5px;
    }
}

.fc-daygrid-day fc-day fc-day-fri fc-day-past {
  border: 1px solid var(--fc-border-color,#ddd) !important;
    color: #000 !important;
}

@media print {
    body {-webkit-print-color-adjust: exact;
    }
}
