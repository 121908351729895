/*
 * Base styles are the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */
/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
html {
    //background-color: #efeeea;
    color: #222222;
    font-size: 1em;
    height: 100%;
    line-height: 1.4;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::-moz-selection {
    background: #B3D4FC;
    text-shadow: none;
}

::selection {
    background: #B3D4FC;
    text-shadow: none;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
    resize: vertical;
}

.grv-widget-tag {
    display: none;

    &.grv-widget-app {
        display: block;
    }
}


html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
    background: transparent;
    border: 0;
    font-size: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

article, aside, figure, footer, header, hgroup, nav, section {
    display: block;
}

header, section, footer {
    clear: both;
}

html {
    overflow-y: scroll;
    //color: #F2F6F9;
}

ul, ol {
    list-style: none;
    margin: 0;
}

table {
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
}

td, td img {
    vertical-align: top;
}

button, input, select, textarea {
    margin: 0;
    outline: none;
}

input::-moz-focus-inner, button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input, select {
    vertical-align: middle;
}

input[type=radio], input[type=checkbox] {
    margin: 1px 4px 4px 0;
}

textarea {
    -webkit-appearance: none;
    border-radius: 0;
    overflow: auto;
    resize: vertical;
}

button, input[type=submit] {
    overflow: visible;
    width: auto;
}

.ie7 input[type=submit], .ie7 input[type=reset], .ie7 input[type=button] {
    filter: chroma(color=#000000);
}

input[type=button],
input[type=submit],
button {
    -webkit-appearance: none;
    border-radius: 0;
    cursor: pointer;
}

blockquote, q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted #000000;
    cursor: help;
}

small {
    font-size: 85%;
}

strong {
    font-weight: bold;
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

hr {
    border: 0;
    border-top: 1px solid #EEEEFA;
    display: block;
    font-size: 0;
    height: 1px;
    line-height: 0;
    margin: 20px 0;
    padding: 0;

    &.dashed-line {
        border-top: 1px dashed #E4E1E1;
    }
}

pre, code, kbd, samp {
    font-family: monospace, sans-serif;
}

pre {
    white-space: pre; /* CSS2 */
    white-space: pre-wrap; /* CSS 2.1 */
    white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
    word-wrap: break-word; /* IE */
}

.ie7 img {
    -ms-interpolation-mode: bicubic;
}

.clear {
    clear: both;
    font-size: 0;
    height: 0;
    line-height: 0;
}

.center {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

