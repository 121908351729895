/* Styling for the Slim Loading Progress Bar container */
.slim-loading-bar {
    left: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
}

/* Styling for the Slim Loading Progress Bar itself */
.slim-loading-bar-progress {
    background-color: green;
    box-shadow: 0 0 10px 0; /* Inherits the font color */
    color: green;
    height: 2px;
    margin: 0;
    opacity: 0;
    padding: 0;
    -moz-transition: all 0.5s ease-in-out;

    /* Add CSS3 styles for transition smoothing */
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    z-index: 99998;
}
