@import 'styles/ui/variables/variables';
@import 'styles/ui/base/variables';
@import 'styles/ui/variables/theme';
@import 'styles/ui/mixins/transitions';

/** Legacy mat-tables */
:not(.mat-card-footer) {
    > .mat-paginator {
        background-color: transparent;
        border-top: 1px solid #E8E8E8;
    }
}

/** Adding new styles in _material_table scss for updated "payhoa-table" */
.mat-table:not(.payhoa-table):not(.pmc-layout-table) {
    .with-sort {
        span {
            margin-right: 18px;
        }
    }

    /** TODO: Let's collect ALL mat-column widths here to consolidate */
    .mat-column-status {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        max-width: 20%;
        gap: 0.5rem;

        > *,
        .mailing-status {
            display: inline-flex !important;
            max-width: 100%;
            min-width: 32px;
        }
    }

    .mat-column-date,
    .mat-column-currency {
        flex: 0 0 15% !important;
        max-width: 8rem;
        width: 15% !important;

        &.with-time {
            flex: 0 0 22% !important;
            max-width: 22rem;
            width: 22% !important;
        }
    }

    .mat-column-position {
        flex: 0 0 5% !important;
        max-width: 5rem;
        min-width: 4rem;
        text-align: right;
        width: 5% !important;

        &.mat-cell {
            padding-right: 1.35rem;
        }
    }

    .mat-column-short {
        flex: 0 0 10% !important;
        max-width: 5rem;
        width: 10% !important;
    }

    .mat-column-checkmark {
        flex: 0 0 12% !important;
        max-width: 8rem;
        width: 12% !important;
        justify-content: center;
    }
}

/** New class to replace .table-responsive */
.payhoa-table-responsive {
    display: grid;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: auto;
    width: 100%;
    will-change: transform;

    &.no-scroll {
        overflow: hidden !important;

        .payhoa-table {
            min-height: 200px;
            overflow: hidden !important;
        }
    }
}

.payhoa-table-tabs {
    .mat-tab-header {
        border-bottom: 0;
    }
}

/** New class to use above mat-tables */
.payhoa-table-actions {
    background-color: $gray-lightest;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px;

    > .flex-wrap {
        row-gap: 0.5rem;
    }

    .mat-progress-spinner {
        align-items: center;
        display: flex;
        margin: auto 0.75rem;
    }

    .row-gap {
        row-gap: 1rem;
    }

    &.row-gap {
        row-gap: 1rem;
    }
}

.card {
    > .card-body:first-child {
        > .payhoa-table-actions:first-child {
            border-radius: 4px 4px 0 0;
        }
    }
}

/** New styles for mat-tables */
.mat-table.payhoa-table:not(.pmc-layout-table) {
    display: grid;
    font-family: $fontreg;
    align-content: flex-start;
    gap: 0;
    overflow-y: auto;

    @media (max-width: 945px) {
        display: block;
    }

    dl {
        display: flex;
        flex-direction: column;

        dt, dd {
            display: flex;
        }
    }

    .text-success {
        color: $mat-success-border-color !important;
    }

    .text-danger {
        color: $mat-warn-border-color !important;
    }

    .text-warning {
        color: $colortextdark !important;

        .fa-warning {
            color: $mat-notice-color !important;
            display: flex;
            align-self: center;
            margin-top: -2px;

            &:before {
                z-index: 2;
            }

            &:after {
                content: ' ';
                display: inline-flex;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 6px 12px 6px;
                border-color: transparent transparent $colortextdark transparent;
                position: absolute;
                transform: rotate(0deg) translate(1px, 0);
                z-index: 1;
            }
        }
    }

    .status-banner {
        background-color: rgba($mat-success-border-color, 0.7);
        color: $white;
        display: flex;
        font-family: $fontsemibold;
        font-weight: 600;
        letter-spacing: 0.05rem;
        padding: 1px 15px;
        position: absolute;
        bottom: 0.125rem;
        right: 0;
    }

    .description-text {
        color: $colordescription;
        display: block;
        font-family: $fontreg;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2rem;
    }

    .mat-divider {
        border-top-color: $offsetgray;
        margin-top: 0.125rem;
        padding-top: 0.125rem;
        width: 100%;
    }

    .mat-button-group {
        margin-top: -0.45rem;
        flex-wrap: nowrap;

        .mat-button-container:not(:first-child) {
            margin-left: -1px;
        }
    }

    .mat-button-container:not(:first-child) {
        &.owner-button,
        &.unit-button {
            button, a {
                margin-top: 0.125rem;
            }
        }
    }

    .mat-hidden {
        opacity: 0 !important;

        @include hover-transition(ease-in-out);
    }

    button, a {
        &.mat-button-link {
            border: 0;
            font-size: 13px;
            line-height: 1.125rem;
            min-height: auto !important;
            text-align: left;
            white-space: normal;
        }
    }

    .answer-list {
        margin-bottom: 0.25rem;

        p, span {
            font-size: 13px;
            line-height: 1.125rem;
            margin: 0;
        }
    }

    .mat-list {
        padding: 0;

        .mat-list-item {
            font-size: 14px;
            height: auto;

            .mat-list-item-content {
                padding: 0;
            }
        }
    }

    .mat-header-cell,
    .mat-cell,
    .mat-footer-cell {
        min-width: 9rem;
        padding-right: 15px;
        position: relative;
    }

    .mat-header-cell,
    .mat-sort-header-arrow {
        font-family: $fontsemibold;
        font-weight: 600;
        line-height: 1;
        color: $text-table-header;
        text-transform: uppercase;
    }

    .mat-cell,
    .mat-footer-cell {
        font-family: $fontreg;
        color: $text-table-cell;
        line-height: normal;
    }

    .mat-cell {
        height: 100%;
        padding-top: 15px;
        padding-bottom: 15px;

        &.clickable {
            cursor: pointer !important;

            &.active {
                button, a {
                    &:hover, &:focus {
                        &.with-mat-icon {
                            .mat-icon {
                                color: $input-btn-text-hover !important;
                            }

                            .mat-button-focus-overlay {
                                border-bottom-color: $mat-link-color !important;
                                opacity: 0.08;
                            }
                        }
                    }
                }
            }

            button, a {
                &.mat-button-active {
                    &.with-mat-icon {
                        .mat-icon {
                            color: $input-btn-text-hover !important;
                        }

                        .mat-button-focus-overlay {
                            border-bottom-color: $mat-link-color !important;
                            opacity: 0.08;
                        }
                    }
                }
            }
        }
    }

    .mat-header-row {
        background: $lightgray;
        border-top: 1px solid $offsetgray;
        border-bottom: 1px solid $offsetgray;
        min-height: 44px;

        .mat-header-cell {
            .mat-sort-header-content {
                text-align: left;
            }
        }
    }

    .mat-row {
        border-bottom: 1px dashed $offsetgray;

        &:last-child {
            border-bottom: 0 !important;
        }
    }

    /** column classes across new tables */
    .mat-column-add,
    .mat-column-expand,
    .mat-column-actions,
    .mat-column-checkmark,
    .mat-column-select {
        justify-content: center;
    }

    [class*='mat-column-total'],
    [class*='mat-column-amount'],
    .mat-column-check_number {
        justify-content: flex-end;

        .mat-list .mat-list-item .mat-list-item-content {
            justify-content: end;
        }
    }

    [class*='mat-column'] {
        &.mat-column-wider {
            max-width: 25rem;
            min-width: 20rem;
        }

        &[class*='date'],
        &[class*='Date'],
        &.mat-column-lastLogin,
        &.mat-column-shorter {
            max-width: 8rem;
            min-width: 7rem;
        }

        &[class*='icon'],
        &.mat-column-expand {
            max-width: 4rem;
            min-width: 2.85rem;

            &.mat-column-attachments {
                max-width: 12rem;
                min-width: 10rem;
            }
        }

        &[class*='amount'],
        &.mat-column-checkmark,
        &.mat-column-check_number {
            max-width: 8rem;
            min-width: 7rem;
        }

        &.mat-column-checkmark,
        &.mat-column-expand {
            padding-right: 0;
        }

        &.mat-column-select {
            max-width: 3rem;
            min-width: 3rem;
            padding-left: 0;
            padding-right: 0.5rem;
        }

        &.mat-column-actions {
            max-width: 6rem;
            min-width: 6rem;
            padding-right: 0;
        }
    }

    /** mat-column that contains a floating status-banner */
    .mat-column-with-banner {
        overflow: visible !important;
        padding-bottom: 30px !important;
    }

    /** mat-checkbox column */
    .mat-column-select {
        .mat-checkbox {
            align-items: center;
            display: flex;

            .mat-checkbox-ripple {
                left: calc(50% - 16px);
                top: calc(50% - 16px);
                height: 32px;
                width: 32px;

                .mat-ripple-element {
                    height: 32px !important;
                    left: 0 !important;
                    top: 0 !important;
                    width: 32px !important;

                    + .mat-ripple-element {
                        background-color: $mat-link-color;
                        opacity: 0.08 !important;
                    }
                }
            }

            &.mat-checkbox-disabled {
                opacity: 0 !important;
            }
        }

        &.mat-cell {
            .mat-checkbox {
                margin: auto;
            }
        }
    }
}

/** Adjust table for mat-dialog */
.mat-dialog-container {
    .mat-table.payhoa-table {
        .mat-header-cell,
        .mat-cell,
        .mat-footer-cell {
            max-width: inherit;
            min-width: inherit;
            width: auto;
        }
    }
}

/** Expandable Rows */
.mat-table.payhoa-table.multiple-data-rows {
    .mat-row {
        border-bottom: 0;
        min-height: 68px;

        &.primary-row:not(:first-of-type) {
            border-top: 1px dashed $offsetgray;
        }

        &.primary-row {
            &.active > .mat-cell {
                padding-bottom: 0;
            }
        }

        &.secondary-row {
            min-height: auto;

            .mat-icon.expanded-row {
                color: $text-icon;
                transform: scale(0.65) translateX(1px) translateY(-2px);
            }

            > .mat-cell {
                opacity: 0;
                padding: 0;
            }
        }

        &.expanded {
            &.secondary-row {
                > .mat-cell {
                    opacity: 1;
                    padding-bottom: 15px;
                }

                .mat-table {
                    .mat-row {
                        border-bottom: 0;
                        min-height: auto;
                    }

                    .mat-cell {
                        padding-top: 0.25rem;
                        padding-bottom: 0.25rem;
                    }
                }

                &.row-details {
                    > .mat-cell {
                        background-color: $lightgray;
                        border-top: 1px dashed $offsetgray;
                    }
                }
            }
        }
    }

    > .mat-row > .mat-table > .mat-row {
        .mat-icon.expanded-row {
            padding-left: 2.35rem;
        }
    }
}
