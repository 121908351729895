@import 'styles/ui/base/variables';

.mat-chip.mat-standard-chip {
    font-family: $fontsemibold;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.3;
    min-height: 24px;
    padding: 4px 8px;
    white-space: nowrap;
    border-radius: 16px;

    &.mat-primary {
        &.default {
            background-color: rgba(#457cb9, 0.1);
            color: #457CB9;

            &:after {
                background-color: lighten(#457CB9, 10%);
            }
        }

        &.draft {
            background-color: rgba(#72869a, 0.1);
            color: #72869A;

            &:after {
                background-color: lighten(#72869A, 10%);
            }
        }

        &.scheduled {
            background-color: rgba(#6369d5, 0.1);
            color: #6369d5;

            &:after {
                background-color: lighten(#6369d5, 10%);
            }
        }

        &.warning {
            background-color: rgba(#ff8e3d, 0.1);
            color: #FF8E3D;

            &:after {
                background-color: lighten(#FF8E3D, 10%);
            }
        }

        &.sent {
            background-color: rgba(#0070e0, 0.1);
            color: #0070e0;

            &:after {
                background-color: lighten(#0070e0, 10%);
            }
        }

        &.success {
            background-color: rgba(#008fa0, 0.1);
            color: #008FA0;

            &:after {
                background-color: lighten(#008FA0, 10%);
            }
        }

        &.error {
            background-color: #bd2130;
            color: $white;

            &:after {
                background-color: lighten(#bd2130, 10%);
            }
        }

        &.muted {
            background-color: rgba(#6c757d, 0.1);
            color: #6c757d;

            &:after {
                background-color: lighten(#6c757d, 10%);
            }
        }
    }
}
