@mixin stagger-animation($multiplier: 3, $duration: 3) {
    @for $i from 1 through $multiplier {
        &:nth-of-type(#{$i}n) {
            animation-delay: #{random($multiplier * 3) * -1}s;
            animation-duration: #{$duration}s;
        }
    }
}

@mixin delay-animation($multiplier: 3, $delay: 0.125) {
    @for $i from 1 through $multiplier {
        &:nth-of-type(#{$i}n) {
            animation-delay: #{$i * $delay}s;
        }
    }
}
