/*********
 * Fonts *
 *********/

/** Main PayHOA app font */
@font-face {
    font-family: 'ProximaNovaRegular';
    src: url('/assets/fonts/Proxima Nova Regular.otf');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'ProximaNovaSemibold';
    src: url('/assets/fonts/Proxima Nova Semibold.otf');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'ProximaNovaLight';
    src: url('/assets/fonts/Proxima Nova Light.otf');
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: 'ProximaNovaBold';
    src: url('/assets/fonts/Proxima Nova Bold.otf');
    font-style: normal;
    font-weight: 700;
}

/** 2023 Landing page font (Remove once moved to WP) */
@font-face {
    font-family: 'InterBold';
    src: url('/assets/fonts/Inter-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'InterSemibold';
    src: url('/assets/fonts/Inter-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'InterRegular';
    src: url('/assets/fonts/Inter-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'InterLight';
    src: url('/assets/fonts/Inter-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

/** Existing marketing font (Remove once feature pages phased out) */
@font-face {
    font-family: 'GraphikBlack';
    src: url('/assets/fonts/Graphik-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'GraphikBold';
    src: url('/assets/fonts/Graphik-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'GraphikSemibold';
    src: url('/assets/fonts/Graphik-Semibold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'GraphikLight';
    src: url('/assets/fonts/Graphik-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'GraphikMedium';
    src: url('/assets/fonts/Graphik-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}
