/* ==========================================================================
	 Helper classes
	 ========================================================================== */
@use 'sass:math';

@import 'styles/ui/mixins/animate';

.pointer {
    cursor: pointer;
}

/*
 * Hide visually and from screen readers
 */
.hidden {
    display: none !important;
}

.invisible {
    margin: 0 !important;
    max-height: 0;
    opacity: 0;
    padding: 0 !important;
    visibility: hidden;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {
    visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clearfix:before, .clear:before,
.clearfix:after, .clear:after {
    content: ' '; /* 1 */
    display: table; /* 2 */
}

.clearfix:after, .clear:after {
    clear: both;
}

/** Custom Font **/
.small-font-1 {
    font-size: 0.8rem;
}

.small-font-2 {
    font-size: 0.8rem;
}

.small-font-3 {
    font-size: 0.7rem;
}

.small-font-4 {
    font-size: 0.6rem;
}

.letter-space-1 {
    letter-spacing: 0.0065rem;
}

.letter-space-2 {
    letter-spacing: 0.003rem;
}

.pre-wrap {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.pre-line-wrap {
    white-space: pre-line;
}

.link-primary {
    color: $brand-primary;
    cursor: pointer;

    &:hover {
        color: $brand-info;
    }
}

.nuevo {
    font-family: $font-family-nuevo;
}

/** Custom Table **/
.borderless td, .borderless th {
    border: none;
}

// Extend the animation library
.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.animated-short {
    animation-duration: 0.2s;
    animation-fill-mode: both;
}

.animated-delay {
    @include delay-animation(9);
}

.animated-delay-lg {
    @include delay-animation(24);
}

// Position Helpers
.p-relative {
    position: relative;
}

.p-absolute {
    position: absolute !important;
}

.p-sticky {
    position: sticky !important;
    top: 0;
}

.r-0 {
    right: 0;
}

@keyframes fadeIn100 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translate3d(25%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInRight {
    animation-name: fadeInRight;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    animation-name: fadeOut;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
}

@keyframes fadeInTop {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInTop {
    animation-name: fadeInTop;
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translate3d(0, 25%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInBottom {
    animation-name: fadeInBottom;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.animated-background {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F7F8;
    background: linear-gradient(to right, #EEEEEE 8%, #DDDDDD 18%, #EEEEEE 33%);
    background-size: 800px 104px;
    border-radius: 8px;
    height: 100%;
    opacity: 0.3;
    position: relative;
}

.background-masker {
    background: #FFFFFF;
    position: absolute;
}

/**
 * Media Query targeted styles.
 */
.device-width {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 1180px) {
    .device-width {
        padding-left: auto;
        padding-right: auto;
        width: 1153px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1179px) {
    .device-width {
        padding-left: 30px;
        padding-right: 30px;
        width: 1024px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .device-width {
        padding-left: 40px;
        padding-right: 40px;
        width: 768px;
    }
}

@media screen and (max-width: 767px) {
    .device-width {
        padding-left: 20px;
        padding-right: 20px;
        width: 320px;
    }
}

.doc-info {
    color: #D2E1EE;
    font-size: 12px;
}

.green {
    color: #49A649;
}


// Small Simple Loaders
$base-line-height: 24px;
$blue: rgb(45, 64, 150);
$off-blue: rgba($blue, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    50% {
        background: $blue;
    }
}

.loading {
    animation: spin $spin-duration infinite linear;
    border: .25rem solid $off-blue;
    border-radius: 50%;
    border-top-color: $blue;
    height: $base-line-height;
    width: $base-line-height;

    &--double {
        border-style: double;
        border-width: .5rem;
    }
}

.loading-pulse {
    animation: pulse $pulse-duration infinite;
    animation-delay: math.div($pulse-duration, 3);
    background: $off-blue;
    height: $base-line-height;
    position: relative;
    width: ($base-line-height * 0.25);

    &:before, &:after {
        animation: pulse $pulse-duration infinite;
        background: $off-blue;
        content: '';
        display: block;
        height: math.div($base-line-height, 1.5);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: ($base-line-height * 0.25);
    }

    &:before {
        left: -($base-line-height * 0.5);
    }

    &:after {
        animation-delay: math.div($pulse-duration, 1.5);
        left: ($base-line-height * 0.5);
    }
}
