@use '@angular/material' as mat;

$mat-white-color: white;
$mat-white: (
    500: $mat-white-color,
    contrast: (
        500: rgba(black, 0.87)
    )
);

$mat-black-color: black;
$mat-black: (
    500: $mat-black-color,
    contrast: (
        500: $mat-white-color,
    )
);

$mat-dark: (
    50: #ECECEE,
    100: #C5C6CB,
    200: #9EA1A9,
    300: #7D818C,
    400: #5C616F,
    500: #3C4252,
    600: #353A48,
    700: #2D323E,
    800: #262933,
    900: #1E2129,
    A100: #C5C6CB,
    A200: #9EA1A9,
    A400: #5C616F,
    A700: #2D323E,
    contrast: (
        50: rgba($mat-black-color, 0.87),
        100: rgba($mat-black-color, 0.87),
        200: rgba($mat-black-color, 0.87),
        300: $mat-white-color,
        400: $mat-white-color,
        500: rgba($mat-white-color, 0.87),
        600: rgba($mat-white-color, 0.87),
        700: rgba($mat-white-color, 0.87),
        800: rgba($mat-white-color, 0.87),
        900: rgba($mat-white-color, 0.87),
        A100: rgba($mat-black-color, 0.87),
        A200: rgba($mat-white-color, 0.87),
        A400: rgba($mat-white-color, 0.87),
        A700: rgba($mat-white-color, 0.87),
    )
);

$mat-primary-color: #2895F1;
$mat-primary-color-alt: #0080BF;
$mat-primary-color-hover: #0069d9;
$mat-primary: (
    50 : #e0efff,
    100 : #b3d7ff,
    200 : #80bdff,
    300 : #4da3ff,
    400 : #268fff,
    500 : #007bff,
    600 : #0073ff,
    700 : #0068ff,
    800 : #005eff,
    900 : #004bff,
    A100 : #ffffff,
    A200 : #f2f5ff,
    A400 : #bfceff,
    A700 : #a6baff,
    contrast: (
        50 : rgba($mat-black-color, 0.87),
        100 : rgba($mat-black-color, 0.87),
        200 : rgba($mat-black-color, 0.87),
        300 : rgba($mat-black-color, 0.87),
        400 : $mat-white-color,
        500 : $mat-white-color,
        600 : $mat-white-color,
        700 : $mat-white-color,
        800 : $mat-white-color,
        900 : $mat-white-color,
        A100 : rgba($mat-black-color, 0.87),
        A200 : rgba($mat-black-color, 0.87),
        A400 : rgba($mat-black-color, 0.87),
        A700 : rgba($mat-black-color, 0.87),
    )
);

$mat-accent-color: #3F4A56;
$mat-accent-border-color: #E8EEF4;
$mat-accent-color-hover: #1b222b;
$mat-accent: (
    50 : #e8e9eb,
    100 : #c5c9cc,
    200 : #9fa5ab,
    300 : #798089,
    400 : #5c656f,
    500 : #3f4a56,
    600 : #39434f,
    700 : #313a45,
    800 : #29323c,
    900 : #1b222b,
    A100 : #72aeff,
    A200 : #3f91ff,
    A400 : #0c74ff,
    A700 : #0067f1,
    contrast: (
        50 : rgba($mat-black-color, 0.87),
        100 : rgba($mat-black-color, 0.87),
        200 : rgba($mat-black-color, 0.87),
        300 : $mat-white-color,
        400 : $mat-white-color,
        500 : $mat-white-color,
        600 : $mat-white-color,
        700 : $mat-white-color,
        800 : $mat-white-color,
        900 : $mat-white-color,
        A100 : rgba($mat-black-color, 0.87),
        A200 : rgba($mat-black-color, 0.87),
        A400 : $mat-white-color,
        A700 : $mat-white-color,
    )
);

$mat-warn-color: #dc3545;
$mat-warn-border-color: #bd2130;
$mat-warn-color-hover: #c82333;
$mat-warn: (
    50 : #fbe7e9,
    100 : #f5c2c7,
    200 : #ee9aa2,
    300 : #e7727d,
    400 : #e15361,
    500 : #dc3545,
    600 : #d8303e,
    700 : #d32836,
    800 : #ce222e,
    900 : #c5161f,
    A100 : #fff6f7,
    A200 : #ffc3c6,
    A400 : #ff9095,
    A700 : #ff777c,
    contrast: (
        50 : rgba($mat-black-color, 0.87),
        100 : rgba($mat-black-color, 0.87),
        200 : rgba($mat-black-color, 0.87),
        300 : $mat-white-color,
        400 : $mat-white-color,
        500 : $mat-white-color,
        600 : $mat-white-color,
        700 : $mat-white-color,
        800 : $mat-white-color,
        900 : $mat-white-color,
        A100 : rgba($mat-black-color, 0.87),
        A200 : rgba($mat-black-color, 0.87),
        A400 : $mat-white-color,
        A700 : $mat-white-color,
    )
);

$mat-link-color: #1871dd;
$mat-link-color-hover: lighten($mat-link-color, 10%);

$mat-success-color: #00b294;
$mat-success-border-color: #00997f;
$mat-success-color-hover: #00cca9;

$mat-notice-color: #ffc107;
$mat-notice-border-color: #c79500;
$mat-notice-color-hover: #ffd75e;

// Palettes
$primary: mat.define-palette($mat-primary);
$accent: mat.define-palette($mat-accent);
$warn: mat.define-palette($mat-warn);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
));

$background: map-get($theme, background);
$foreground: map-get($theme, foreground);
