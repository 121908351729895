.cdk-overlay-pane:not(.pmc-layout-dialog) {
    // for material modals
    .mat-dialog-title {
        position: relative;
    }

    .mat-dialog-container {
        border-radius: 12px !important;
        padding: 0 !important;
        position: relative;
    }

    .mat-close {
        position: absolute;
        right: 24px;
        top: 24px;

        &:hover {
            cursor: pointer;
        }
    }

    h3.mat-dialog-title, .modal-title-native {
        color: #34495E;
        font-family: $fontreg;
        font-size: 21px;
        letter-spacing: 0;
        line-height: 1;
        margin: 24px 40px;
        text-align: center;

        /** TODO use this with an added class in some cases */
        //text-align: left;
        //margin: 24px 100px 24px 24px;

        @media (max-width: 767px) {
            margin-left: 24px;
            margin-right: 10px;
            text-align: left;
        }
    }

    h4.mat-dialog-subtitle {
        color: $colortextfeature;
        font-family: $fontsemibold;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.55;
    }

    mat-dialog-content.mat-dialog-content {
        background: #F7F9FC;
        border-top: 1px solid #DFE6EE;
        margin: 0;
        padding: 24px;

        &.feature-content {
            padding: 40px;
        }

        // in cases where minimal content makes the modal look short
        &.small-content {
            padding: 50px;
            text-align: center;

            label {
                float: left;
            }
        }

        // in cases where the only content is a table; want it flush with sides
        &.has-table {
            padding: 0;
        }

        p:last-child, table.table {
            margin-bottom: 0;
        }

        .menu-toolbar {
            background: #FAFAFA;
            border-bottom: 1px solid #DFE6EE;
        }

        .lower-text {
            color: $colortextdark;
        }
    }

    mat-dialog-actions.mat-dialog-actions {
        border-top: 1px solid #DFE6EE;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 10px 24px;

        .btn {
            padding-left: 24px;
            padding-right: 24px;
        }

        // if there is just one button like an 'ok' or something; send it to the right
        &.one-button {
            justify-content: end;
        }
    }

    &.demo-scheduler-dialog {
        .mat-dialog-container {
            background: none;
        }
    }
}
