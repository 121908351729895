@import 'styles/ui/base/variables';
@import 'styles/ui/variables/variables';
@import 'styles/ui/variables/theme';

body {
    background: #EFF2F5;
    color: $body-color;
    font: normal normal 13px/24px $fontreg;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    -webkit-text-size-adjust: 100%;
}

body.for-login {
    background: #475163;
}

/** TODO: remove once all lf-modals are replaced with mat-dialog */
body.modal-active {
    mat-sidenav.payhoa-sidenav {
        z-index: 1;
    }
}

a {
    color: #247FDE;
    text-decoration: none;
}

.add-blue {
    color: #247FDE !important;
}

.add-green {
    color: #62BAB2 !important;
}

.add-gray-blue {
    color: #3F4A56 !important;
}

.add-ashy-blue {
    color: #8091A5 !important;
}

.text-ellipsis {
    text-overflow: ellipsis !important;
}

.line-through {
    text-decoration: line-through;
}

/** TODO: refactor to read font-size-smaller */
.text-font-smaller {
    font-size: 85%;
}

/** borrowing from app component's .h3 declaration */
.font-size-larger {
    font-size: 1.75rem;
}

.font-weight-medium {
    font-weight: 600 !important;

    &:not(.mat-icon) {
        font-family: $fontsemibold !important;
    }
}

.font-weight-bold {
    font-weight: 700 !important;

    &:not(.mat-icon) {
        font-family: $fontbold !important;
    }
}

.font-weight-normal {
    font-weight: 400 !important;

    &:not(.mat-icon) {
        font-family: $fontreg !important;
    }
}

// Styles for formly forms
formly-form small {
    font-size: 100%;
}

.custom-file-input {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
}

.custom-upload-label {
    background-color: #0E86E0;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    height: auto;
    margin: 10px auto;
    padding: 10px 15px;
    text-transform: capitalize;
}

// Styles for dragula drag and drop
/* in-flight clone */
.gu-mirror {
    background: white;
    margin: 0 !important;
    pointer-events: none;
    //-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    //filter: alpha(opacity=80);
    position: fixed !important;
    //border: 1px solid #c8c8c850;
    //border-radius: 4px;
    z-index: 9999 !important;
    //padding: 10px;
}

/* high-performance display:none; helper */
.gu-hide {
    left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    -webkit-user-select: none !important;
    user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
    filter: alpha(opacity=20);
    opacity: 0.2;
}

// 'add new' card box like on Transactions > bank acct list, inquiries > add new form type
.add-new {
    background: transparent;
    border: 2px dashed #97A4B1;
    color: #667587;
    font-family: $fontlight;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;

    &:hover {
        cursor: pointer;
    }

    img {
        display: block;
        margin: 0 auto 10px auto;

        &.plus-sign {
            width: 20px;
        }
    }
}

a:hover {
    opacity: 0.75;
    text-decoration: none;
}

.btn-primary {
    .fa-plus,
    .fa-chevron-down,
    .fa-chevron-up,
    .fa-check,
    .fa-arrow-right {
        -webkit-text-stroke: 1px #007BFF;
    }
}

.btn-outline-primary {
    .fa-plus,
    .fa-chevron-down,
    .fa-chevron-up,
    .fa-check {
        -webkit-text-stroke: 1px white;
    }

    &:hover {
        .fa-plus,
        .fa-chevron-down,
        .fa-chevron-up,
        .fa-check {
            -webkit-text-stroke: 1px #007BFF;
        }
    }
}

.btn-outline-secondary {
    .fa-plus,
    .fa-chevron-down,
    .fa-chevron-up,
    .fa-check {
        -webkit-text-stroke: 1px white;
    }
}

.btn-custom-success {
    background-color: #58C1A9;
    color: white;

    .fa-plus,
    .fa-chevron-down,
    .fa-chevron-up,
    .fa-check {
        -webkit-text-stroke: 1px #58C1A9;
    }

    &:hover {
        color: white;
        border: 1px solid #6B9890;

        i {
            color: white;
        }
    }
}

p {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 15px;
}

h1 {
    color: $colortextdark;
    font: normal normal 24px/30px $fontreg;
    margin-bottom: 10px;
}

h2 {
    color: $colortextdark;
    font: normal normal 24px/30px $fontreg;
    margin-bottom: 20px;

    &.feature-section-header {
        color: $colortextfeature;
        font-family: $fontsemibold;
        font-size: 32px;
        line-height: 1.5;
        margin-bottom: 16px;
        max-width: 640px;

        &.inverse {
            color: $white;
        }
    }
}

.feature-header {
    color: $colortextfeature;
    font: normal normal 21px/24px $fontsemibold;

    &.inverse {
        color: $white;
    }
}

.feature-text {
    color: $colortextfeaturelight;
    font: normal normal 18px/28px $fontreg;
    max-width: 720px;
    margin-bottom: 32px;
}

.feature-item {
    color: $colortextfeature;
    font: normal normal 21px/32px $fontsemibold;
    margin-bottom: 0.5rem;

    .checkmark {
        left: 0.35rem;
        margin-top: 0.25rem;
        position: absolute;
    }
}

.feature-price-text {
    color: $colortextfeature;
    font: normal normal 56px/56px $fontbold;

    &.inverse {
        color: $white;
    }

    small {
        font-size: 24px;
        line-height: 1.2;
    }
}

.feature-list {
    .checkmark.blue {
        color: #0086B1;
    }
}

.feature-content {
    ul, ol, p {
        color: $colortextfeature;
        font-size: 16px;
        line-height: 1.5;
        margin-top: 0.5rem;
    }

    ul, ol {
        li {
            border: 0;
        }
    }

    ul {
        li {
            padding: 0 1.25rem 0.35rem;

            &:before {
                content: "•";
                margin-top: -0.1rem;
                margin-left: -0.75rem;
                position: absolute;
            }
        }
    }

    ol {
        list-style: decimal;
        padding-left: 1.25rem;

        li {
            padding: 0 0 0.35rem;
        }
    }
}

select {
    + .btn {
        margin-left: 8px;
    }
}

button, html [type='button'], [type='reset'], [type='submit'] {
    -webkit-appearance: inherit;
    border: none;

    &:focus {
        outline: none;
    }
}

.btn {
    border: 1px solid transparent;
    cursor: default;
    font-family: $fontsemibold;
    font-size: 14px;
    line-height: 1;
    padding: 10px 11px;

    &.btn-lg {
        font-size: 16px;
        padding: 10px 60px;
    }

    &:not(.disabled) {
        &:not(:disabled) {
            cursor: pointer;
        }
    }

    &.btn-outline-secondary {
        background-color: $white !important;
        border-color: $input-btn-border-color;
        box-shadow: rgba(49, 53, 65, 0.1) 0 1px 2px 0;
        color: $input-btn-text-color !important;

        a {
            color: $input-btn-text-color !important;
        }

        &:not(.disabled) {
            &:not(:disabled) {
                &:hover,
                &.active {
                    background-color: $white;
                    color: $input-btn-text-color !important;
                }

                &:hover,
                &:focus,
                &.focused {
                    border-color: $input-btn-border-hover !important;
                }

                &.active {
                    border-color: $input-btn-text-color !important;
                }
            }
        }
    }

    .fa, img {
        margin-right: 5px;
    }

    .plus-img {
        margin-right: 7px;
        margin-top: -3px;
    }

    + .btn {
        margin-left: 8px;
    }

    &.btn-link {
        &:hover {
            text-decoration: none;
        }
    }
}

.btn-save-container {
    .btn {
        border-radius: 0.25rem !important;

        &:first-child,
        &:first-child:hover {
            border-color: transparent !important;
            box-shadow: none;
            cursor: default !important;
            outline: none !important;
        }
    }
}

.fa-info-circle.gray {
    color: #A6B1BE;
}

.mailing-status {
    border-radius: 4px;
    display: inline-block;
    font-family: $fontsemibold;
    font-size: 12px;
    line-height: 15px;
    padding: 4px 6px;

    // moving -1px to account for border causing alignment issues when using flexbox alignment
    position: relative;
    top: -1px;

    &.bad, &.failed {
        background: #FCEEEE;
        color: #EB5858;
    }

    &.warning, &.pending {
        background: #F9EABD8A;
        color: #FFA66E;
    }

    &.good, &.delivered, &.complete {
        background: #E1F7F2;
        color: #53C1A9;
    }

    &.default, &.sending, &.processing, &.other, &.ok {
        background: #EEF2F5;
        color: #8091A5;
    }

    &.none {
        background: #EEF2F5;
        color: #0070E0;
    }

    &.inverse {
        background: rgba($white, 0.1);
        color: $white;
    }
}

// just general table styles...
table, .table {
    background: white;
}

td {
    font-size: 14px;
    line-height: 1.2;
}

.card-body > .table th {
    border-top: none !important;
}

.table {
    &.table-bordered {
        th, td {
            line-height: 1;
            padding: 12px;
        }
    }

    th, td {
        border-top: none;
        display: table-cell;
        font-family: $fontreg;
        line-height: 1;
        margin-bottom: 0;
        padding: 15px;

        &:first-child {
            padding-left: 25px;
        }

        &:last-child {
            padding-right: 25px;
        }
    }

    td {
        background: #FFFFFF;
        border-bottom: 2px dotted #F2F6F9;
        vertical-align: middle;

        img {
            vertical-align: middle;
        }
    }

    th {
        background: #F8FAFB;
        border-bottom: 1px solid #DFE6EE !important;
        border-top: 1px solid #DFE6EE !important;
        color: #34495E;
        font-size: 12px;
        letter-spacing: 0.03rem;
        line-height: 1;
        padding-bottom: 10px;
        padding-top: 15px;
        text-transform: uppercase;

        span {
            font-size: 11px;
        }

        &:not(.align-bottom) {
            vertical-align: unset !important;
        }
    }

    thead tr + tr {
        th {
            background: lighten(#F8FAFB, 0.5%);
        }
    }

    tr:last-child {
        td {
            border-bottom: none;
        }
    }
}

.remove-background {
    &.card, &.card .card-header {
        background: #EFF2F5;
        border: 0;
        box-shadow: none;
    }
}

.add-hover:hover {
    cursor: pointer;
}

// overriding typical bootstrap styles
.card,
.card-deck .card {
    background-color: $white;
    border: none;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(52, 73, 94, 0.1);
    margin-bottom: 20px;

    .card-header {
        background: white;
        border-bottom: 1px solid #DFE6EE;
        padding: 0;

        h2 {
            color: #34495E;
            font-family: $fontsemibold;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            margin: 16px 0 15px 15px;
        }

        .btn-link {
            font-family: $font-size-base;
            font-size: 14px;
            line-height: 1;
            margin-bottom: 0;
            padding: 0;

            &:last-child {
                margin-right: 0;
                padding-right: 0;
            }
        }

        .btn {
            height: 36px;
            margin: 10px 8px;

            &:last-child {
                margin-right: 18px;
            }

            &.btn-primary a {
                color: white;
            }
        }

        a.btn.btn-primary {
            color: white;
        }

        &.with-gradient {
            background: linear-gradient(135deg, $white, $lightgray);
        }
    }

    .card-body {
        &.full-height {
            flex: 1 0 0;
            height: 100%;
        }

        .table {
            margin-bottom: 0;
        }
    }

    img.img-fluid {
        border-radius: 4px;
    }

    .set-height-empty {
        padding: 220px 30px;
        text-align: center;

        img.img-fluid {
            margin-bottom: 20px;
        }
    }

    .feature-img {
        flex: 0 0 50%;

        img.img-fluid {
            &.rounded-left {
                @include media-breakpoint-up(md) {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }
            }
        }
    }

    .featured-image-col {
        margin-bottom: 20px;
        max-width: 225px;

        &.lg {
            max-width: 450px;
        }
    }

    &.card-outline {
        background: none;
        box-shadow: none;

        > .card-header {
            background: none;
            border: 0;

            h2 {
                margin-left: 0;
            }

            .card-header-count {
                font-family: $fontreg;
                font-size: 0.875rem;
                font-weight: 400;
                padding-left: 0.5rem;
            }
        }
    }

    &.bg-blue {
        background-color: #0080BF;
        .card-body {
            background: url(/assets/images/v2/dots.png) center bottom repeat-x;
        }
    }

    &.add-hover {
        &:hover {
            box-shadow: 0 1px 4px 0 rgba($mat-accent-color-hover, 0.35);
        }

        &.active {
            box-shadow: 0 1px 4px 0 rgba($mat-accent-color-hover, 0.35);

            .card-header {
                background: $mat-primary-color-hover;

                &.with-gradient {
                    background: $mat-primary-color-hover;
                }
            }
        }
    }
}

.list-group-item {
    padding-left: 25px;
    padding-right: 25px;

    &.list-group-item-placeholder {
        background-color: $gray-100;
        color: $colortext;
    }

    &.list-group-header {
        background-color: #F8FAFB;
    }
}

.progress-bar {
    &.bg-success {
        background-color: #53C1A9 !important;
    }
}

.badge {
    background: white;
    border-radius: 12px;
    font-size: 90%;
    font-weight: normal;
    padding: 5px 8px 4px;

    &.badge-default {
        border: 1px solid #A6B1BE;
        color: #A6B1BE;
    }

    &.badge-primary {
        border: 1px solid $colorlink;
        color: $colorlink;
    }

    &.badge-warning {
        border: 1px solid $yellow;
        color: $yellow;
    }

    &.badge-success {
        border: 1px solid #44BA9F;
        color: #44BA9F;
    }

    &.badge-info {
        border: 1px solid #97C8D7;
        color: #97C8D7;
    }
}

// misc other
.no-margin {
    margin-bottom: 0 !important;
}

.input-group-addon {
    height: 31px;
}

.form-control {
    border: 1px solid #D0D6DD;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #97A4B1;
    font-size: 14px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #97A4B1;
    font-size: 14px;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #97A4B1;
    font-size: 14px;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #97A4B1;
    font-size: 14px;
}

select.form-control {
    font-size: 14px;
    padding: 0 9px;
}

.open > .dropdown-menu {
    display: block;
}

.collapse.open {
    display: block;
}

h3.popover-title {
    font-size: 18px;
    margin-top: 15px;
}

.dropdown-menu {
    border: none;
}

.card-columns {
    column-count: 2;
}

h2 {
    @include media-breakpoint-between(sm, xl, $grid-breakpoints) {
        color: red;
    }
}

.card-columns {
    @include media-breakpoint-only(xs) {
        column-count: 1;
    }
    @include media-breakpoint-only(sm) {
        column-count: 2;
    }
    @include media-breakpoint-only(lg) {
        column-count: 2;
    }
}

.bg-modal {
    background-color: $body-modal;
}

.bg-drawer {
    background-color: $body-drawer;
}

.card-footer {
    border-color: #DFE6EE;
}

.card-header.card-header-standalone {
    border-radius: 4px !important;
}

.card-header.card-header-bg-light {
    background-color: #FAFAFA;
}

.w-100 {
    &.w-md-25,
    &.w-sm-25 {
        width: 25% !important;
    }

    &.w-md-40,
    &.w-sm-40 {
        width: 40% !important;
    }

    &.w-md-50,
    &.w-sm-50 {
        width: 50% !important;
    }

    &.w-md-60,
    &.w-sm-60 {
        width: 60% !important;
    }

    &.w-md-25,
    &.w-md-40,
    &.w-md-50,
    &.w-md-60 {
        @include media-breakpoint-down(md) {
            width: 100% !important;
        }
    }

    &.w-sm-25,
    &.w-sm-40,
    &.w-sm-50,
    &.w-sm-60 {
        @include media-breakpoint-down(sm) {
            width: 100% !important;
        }
    }
}

.row-gap-mobile {
    @include media-breakpoint-down(sm) {
        row-gap: 1rem;
    }
}

/** Introduce the "new" rounded corners... */
.rounded-xl {
    border-radius: 1.85rem;
}
