@mixin transition($x...) {
    -webkit-transition: $x;
    -moz-transition: $x;
    -ms-transition: $x;
    -o-transition: $x;
    transition: $x;
}

@mixin hover-transition($ease: ease, $time: 0.3s) {
    @include transition(all $ease $time);
}

@mixin fadein-transition($ease: ease, $time: 0.3s) {
    @include transition(opacity $ease $time);
}

@mixin slideup-transition($ease: ease-out, $time: 0.3s) {
    @include transition(transform $ease $time);
}

@mixin button-transition($ease: ease-in-out, $time: 0.15s) {
    @include transition(color $time $ease, background-color $time $ease, border-color $time $ease, box-shadow $time $ease);
}

@mixin button-border-transition($ease: ease-in-out, $time: 0.15s) {
    @include button-transition($ease, $time);
    @include transition(border-width $time $ease);
}

@mixin delay-transition($multiplier: 3, $delay: 0.3) {
    @for $i from 1 through $multiplier {
        &:nth-of-type(#{$i}n) {
            transition-delay: #{$i * $delay}s;
        }
    }
}
