@import 'styles/ui/variables/variables';
@import 'styles/ui/base/variables';

/** Use for global styles for the app. */

.mat-menu-content button.bg-light {
    &:hover, &:focus {
        background-color: #F8F9FA !important;
    }
}

/** App notification bar */
.notification-bar {
    background: #FFF5EB;
    border-radius: 5px;
    -moz-box-shadow: 0 2px 4px 0 rgba(52, 73, 94, 0.1);
    -webkit-box-shadow: 0 2px 4px 0 rgba(52, 73, 94, 0.1);
    box-shadow: 0 2px 4px 0 rgba(52, 73, 94, 0.1);
    display: block;
    font-family: 'ProximaNovaRegular', Arial, sans-serif;
    margin: 0 0 15px 0;
    position: relative;

    .message-icon {
        border-radius: 5px 0 0 5px;
        color: white;
        display: inline-block;
        padding: 10px 15px;

        @media (max-width: 768px) {
            display: none;
        }
    }

    .messages {
        display: inline-block;
        font-size: 13px;

        @media (max-width: 768px) {
            padding: 15px;
            text-align: center;
        }
    }
}

/** Styling for secondary row below main item in cell for table */
.lower-text {
    color: $colortext;
    font-size: 12px;
    line-height: 12px;
    padding-top: 5px;
    text-transform: uppercase;
}

/** Styling material tabs to match lf tabbed changer styles */
.mat-tab-group {
    .mat-tab-label {
        font-family: $fontsemibold;
        font-size: 16px;
        font-weight: bold;
        line-height: 2.2;
        padding: 27px !important;

        &:hover {
            opacity: .7;
        }

        &.mat-tab-label-active {
            color: $linkcolor;
            opacity: 1;
        }

        &.mat-tab-label-action {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
            margin-left: auto;
            opacity: 1 !important;

            .mat-ripple-element {
                left: 0;
            }
        }
    }

    .mat-tab-body-wrapper {
        font-family: $fontreg;
    }

    &.pad {
        .mat-tab-body-wrapper {
            padding: 30px;
        }
    }

    .mat-ink-bar {
        height: 1px;
    }

    &.mat-primary .mat-ink-bar {
        background-color: #0070E0;
    }

    &.status-tabs {
        .mat-tab-label {
            border-bottom: 1px solid transparent;
            border-left: 1px solid $offsetgray;
            justify-content: flex-start;
            height: auto;
            padding: 17px 27px !important;
            width: 100%;

            .mat-tab-label-content {
                align-items: flex-start;
                flex-direction: column;
                width: 100%;

                .lead {
                    line-height: 1.2;
                    color: $black;
                }

                small {
                    font-family: $fontreg;
                    font-weight: normal;
                    line-height: 0.75;
                }
            }

            &:first-child {
                border-left: 0;
            }

            &:not(:hover, .mat-tab-label-active) {
                background: #F8FAFB;
            }

            &.mat-tab-label-active {
                .mat-tab-label-content {
                    .lead {
                        color: $linkcolor;
                    }
                }
            }
        }

        .mat-ink-bar {
            height: 2px;
        }
    }
}

/** Styling material tabbed-stepper wizard items */
.mat-stepper-horizontal, .mat-stepper-vertical {
    font-family: 'ProximaNovaRegular', Arial, sans-serif;

    .mat-step-header {
        .mat-step-label {
            &.mat-step-label-active {
                color: #0070E0;
            }
        }

        .mat-step-icon {
            background-color: #0070E0;
        }
    }
}

.border-bottom {
    border-bottom: 1px solid #DFE6EE;
    &.dotted {
        border-bottom: 2px dotted #F2F6F9 !important;
    }
}

.border-top {
    border-top: 1px solid #DFE6EE;
    &.dotted {
        border-top: 2px dotted #F2F6F9 !important;
    }
}

/** Custom mat slide toggle styles */
.mat-checkbox-label, .mat-slide-toggle-label label {
    font-family: 'ProximaNovaRegular', Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    text-transform: none;
}

.mat-slide-toggle {
    .mat-slide-toggle-thumb {
        background-color: white;
        height: 16px;
        width: 16px;
    }

    &.left-ripple {
        .mat-slide-toggle-persistent-ripple {
            left: 0 !important;
        }
    }

    &.mat-checked {
        &:not(.mat-disabled) {
            .mat-slide-toggle-bar {
                background-color: #007BFF;
            }

            .mat-slide-toggle-thumb {
                background-color: $white;
            }
        }

        &.mat-disabled {
            .mat-slide-toggle-bar {
                background-color: rgba(0, 123, 255, 0.5);
            }

            .mat-slide-toggle-thumb {
                background-color: rgba($white, 0.5);
            }
        }

        .mat-slide-toggle-thumb-container {
            transform: translate3d(15px, 0, 0);
        }
    }

    .mat-slide-toggle-bar {
        border-radius: 10px;
        height: 20px;
    }

    .mat-slide-toggle-thumb-container {
        left: 2px;
        top: 2px;
    }

    .mat-slide-toggle-content {
        font-family: 'ProximaNovaRegular', Arial, sans-serif;
    }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #53C1A9;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: #B0B0B0;
}

.mat-checkbox-frame {
    border-width: 1px !important;
}

.no-wrap {
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
}

label, span {
    .custom-checkbox {
        display: none;
        position: relative;

        & + span {
            background-image: url(/assets/images/app/icons/check-orange.png);
            background-position: -10px -154px;
            display: inline-block;
            height: 16px;
            margin-bottom: -4px;
            margin-left: 7px;
            margin-right: 5px;
            padding: 0 !important;
            width: 16px;
        }

        &:checked {
            & + span {
                background-image: url(/assets/images/payhoa/other/green-checkbox.svg);
                background-position: -32px -160px;
            }
        }

        &[disabled],
        &[readonly] {
            & + span {
                -webkit-filter: grayscale(1); /* Old WebKit */
                //noinspection CssUnknownProperty
                filter: grayscale(1);
                opacity: 0.5;
            }
        }
    }
}


.image-uploader {
    .drag-and-drop {
        background: #F2F6F9;
        box-sizing: border-box;
        display: block;
        line-height: 1.7;
        margin: 3px 0 0px 0;
        padding: 74px 0;
        position: relative;
        text-align: center;
        vertical-align: top;
        width: auto;

        input[type='file'] {

            height: 100% !important;

            left: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            width: 100%;

            &:hover {
                cursor: pointer;
            }
        }

        &.dropping {
            background: #FFFFFF;
            border: 3px dashed #55B7FF;
        }
    }

    .recommendation {
        color: #BBBBBB;
        font-weight: 100;
        left: 27px;
        padding: 10px 0 0 13px;
        position: absolute;
        z-index: 200;
    }

    .image-preview {
        box-sizing: border-box;
        display: block;
        height: 94px;
        margin: 3px 0 30px 0;
        position: relative;
        text-align: center;
        vertical-align: top;
        width: 225px;
    }

    .uploading {
        margin: 0 auto;
        text-align: center;
        width: 100%;
    }

    lf-image-cropper {
        box-sizing: content-box;
        width: 100%;
    }
}

.new-loader {
    border: 10px solid #F2F6F9;
    border-radius: 50%;
    border-top: 10px solid #1871DD;
    width: 90px;
    height: 90px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.attachments {
    span {
        font-family: 'ProximaNovaSemibold', sans-serif;
        font-size: 12px;

        &.item-size {
            color: #A0ACB7;
        }
    }
}


.swal2-title {
    font-size: 24px;
    margin: -10px auto 30px;
}

.swal2-content {
    font-size: 16px;
}

.fa.fa-info-circle:hover {
    cursor: pointer;
}


.add-scroller {
    height: 370px;
    overflow-y: auto;
}

.add-scroller-max-height {
    max-height: 370px;
    overflow-y: auto;

    img {
        max-width: 450px;
    }
}

.promo-bar-inline {
    font-family: $fontbold;
    background-color: rgb(0, 105, 216);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    color: $white;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 14px;
    text-align: center;

    &.outline {
        background-color: transparent;
        border: 1px solid $colortextfeature;
        box-shadow: none;

        &:not(.inverse) {
            color: $colortextfeature;
        }

        &.inverse {
            border-color: $white;
        }
    }
}

ngb-popover-window {
    &.popover {
        background: #505969;
        font-family: $fontlight;
        font-weight: 600;
        text-align: center;

        .popover-body {
            color: white;
            text-align: center;
        }

        .popover-header {
            background: #505969;
            color: white;
        }

        .arrow {
            &::before, &::after {
                border-bottom-color: #505969 !important;
            }
        }
    }
}
