$colorlink: #247FDE;
$colortext: #97A2B2;
$colortextdark: #3D4E5F;
$colormuted: #97A2B2;
$lightgray: #F8FAFB;
$offsetgray: #DFE6EE;
$footerbg: #37383C;
$footergrey: #87878A;
$linkcolor: #0070E0;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !default;
$font-family-monospace: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: 1.25rem !default;
$font-size-sm: .875rem !default;

$font-weight-light: 300 !default;
$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$lightest-blue: #F8FAFC;
$muted-blue: #BFD5EA;

$blue-0: #F7FBFF;
$blue-1: #E5F2FD;
$blue-2: #94C4F0;
$blue-3: #6797C5;
$blue-4: #298AE2;
$blue-4-5: #2581DE;
$blue-5: rgba(148, 196, 240, 0.5);
$blue-6: #3D4E5F;
$blue-7: #97A2B2;

$lightest-yellow: #FFEED4;

/* Borrowed from bootstrap overrides */
$background-bad: #FCEEEE;
$background-failed: $background-bad;
$text-bad: #EB5858;
$text-failed: $text-bad;
$background-warning: #F9EABD;
$background-pending: $background-warning;
$text-warning: #FFA66E;
$text-pending: $text-warning;
$background-good: #E1F7F2;
$background-delivered: $background-good;
$background-complete: $background-good;
$text-good: #53C1A9;
$text-delivered: $text-good;
$text-complete: $text-good;

/* Mat button */
$text-icon: #97A4B1; // #B0BAC5;
$text-icon-dark: #313541;

/* Mat table text and colors */
$text-table-header: #34495E;
$text-table-cell: $text-icon-dark;

/* Mat form fields and inputs */
$text-placeholder: $text-icon;
$text-input: #6C757D;
$text-input-icon: #3F4A56;
