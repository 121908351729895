@import 'styles/ui/variables/variables';
@import 'styles/ui/base/variables';
@import 'styles/ui/variables/theme';

@mixin mat-text-color($color, $placeholder: true) {
    color: $color !important;
    font-family: $fontreg;
    font-size: 14px;
    letter-spacing: 0;
    line-height: normal;

    @if $placeholder {
        opacity: 0.75;
    }
}

// fixes mat-select inline with buttons
mat-form-field {
    .mat-form-field-wrapper {
        padding-bottom: 0;
    }
}

/** Legacy mat-form-field */
/** Adding new styles in _material_form scss for updated "payhoa-table-actions" form fields */
mat-form-field:not(.payhoa-field) {
    &.bg-white {
        background-color: transparent !important;

        .mat-form-field-outline {
            background: $white !important;
        }
    }

    &.single-select {
        padding: 0;

        .mat-form-field-wrapper,
        .mat-form-field-infix {
            padding: 0;
            border-top: 0;
        }

        .mat-form-field-underline {
            display: none !important;
        }

        .mat-select {
            padding: 10px 11px;
        }

        &:not(.input).mat-select-arrow {
            border: 0;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            height: 100%;
            width: 1em;

            &:before {
                content: '\f078';
            }
        }

        &.wide {
            .mat-form-field-infix {
                width: 320px;

                mat-select-trigger {
                    .text-ellipsis {
                        max-width: 300px;
                    }
                }
            }
        }
    }

    &.mat-form-field-wide {
        .mat-form-field-infix {
            width: 276px;

            mat-select-trigger {
                .text-ellipsis {
                    max-width: 256px;
                }
            }
        }
    }

    &.mat-form-field-type-mat-select,
    &.mat-autocomplete-input {
        .mat-form-field-flex {
            .mat-form-field-outline-thick {
                .mat-form-field-outline-start,
                .mat-form-field-outline-end,
                .mat-form-field-outline-gap {
                    border-width: 1px;
                    border-color: $gray-400;
                }
            }
        }

        &.mat-form-field-invalid {
            .mat-form-field-flex {
                .mat-form-field-outline-thick {
                    .mat-form-field-outline-start,
                    .mat-form-field-outline-end,
                    .mat-form-field-outline-gap {
                        border-color: $colorerror;
                    }
                }
            }
        }

        &.mat-focused {
            .mat-form-field-flex {
                .mat-form-field-outline-thick {
                    border-radius: 5px;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

                    .mat-form-field-outline-start,
                    .mat-form-field-outline-end {
                        border-color: #80BDFF;
                    }
                }
            }
        }
    }

    &.mat-autocomplete-input {
        .mat-form-field-flex,
        .mat-form-field-infix {
            margin: 0;
            padding: 0;
        }

        .mat-form-field-flex {
            .mat-form-field-outline {
                top: 0 !important;
            }

            .mat-form-field-infix {
                border: 0;
                padding: 0.1rem 0;

                input.mat-input-element {
                    box-sizing: border-box;
                    color: #6C757D;
                    font-family: $fontreg;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 1.125;
                    margin-top: 0;
                    padding: 0.375rem 0.75rem;

                    &::placeholder {
                        color: #6C757D;
                        opacity: 1;
                    }

                    &.form-control {
                        &:focus {
                            background: none;
                            border: 0;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }

    &.mat-form-field-appearance-outline {
        // fixes mat-select inline with buttons
        .mat-form-field-wrapper {
            margin: 0 !important;
        }

        // border of the select in normal state
        .mat-form-field-outline {
            color: #D0D6DD;
        }

        // border of the select in focused/hover state
        &.mat-focused .mat-form-field-outline-thick {
            border-width: 1px;
            color: #D0D6DD;
        }

        .mat-form-field-infix {
            padding: 4px 0 10px;
        }

        .mat-select-placeholder {
            font-family: $fontreg;
            color: #97A2B2;
        }

        .mat-select-arrow-wrapper {
            padding-top: 6px;
        }

        /** multiselect styling */
        &.mat-multiselect {
            .mat-form-field-suffix {
                min-width: 18px;
                top: 0.05rem;

                svg {
                    path {
                        @include svg-black-filter();
                        opacity: 0.54;
                    }
                }

                .mat-icon {
                    height: 12px;
                    margin: auto;
                    width: 12px;
                }
            }

            &.mat-focused {
                .mat-form-field-suffix {
                    svg {
                        path {
                            @include svg-mat-icon-dark-blue-filter();
                            opacity: 1;
                        }
                    }
                }
            }

            .mat-select-arrow {
                display: none !important;
            }
        }
    }
}

.menu-toolbar mat-form-field:not(.payhoa-field) {
    &.single-select {
        .mat-form-field-wrapper {
            min-width: 200px;
        }
    }
}

@media (max-width: 768px) {
    .mat-form-field:not(.payhoa-field) {
        &.mat-form-field-wide {
            .mat-form-field-infix {
                width: 200px;
            }
        }
    }
}

/** New class to normalize top of page actions toolbar elements */
.payhoa-toolbar-actions {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 0;
    row-gap: 0.5rem;

    > div {
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.5rem;
    }
}

/** New class to use for new payhoa-field mat-form-field elements */
.mat-form-field.payhoa-field {
    display: flex;
    @include mat-text-color($text-input, false);

    &.mat-form-field-appearance-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            background-color: $white;
            border-color: $input-btn-border-color;
            border-width: 1px;
        }

        .mat-form-field-outline {
            border-radius: 5px;
            box-shadow: rgba(49, 53, 65, 0.1) 0 1px 2px 0;
        }

        .mat-form-field-wrapper {
            margin: 0;
        }

        .mat-form-field-infix {
            display: flex;
            border: 0;
            margin: auto 0;
            padding: 0;
        }

        .mat-form-field-flex,
        .mat-form-field-outline {
            display: flex;
            border: 0;
            margin: 0;
            padding: 0;
        }

        .mat-form-field-flex,
        .mat-form-field-outline,
        .mat-select {
            min-height: 36px;
            top: 0;
        }

        .mat-input-element,
        .mat-select {
            padding: 0 0.75rem;
        }

        .mat-select {
            display: flex;
            @include mat-text-color($text-input, false);

            .mat-select-trigger {
                height: 100%;
            }
        }

        .mat-form-field-prefix,
        .mat-form-field-suffix {
            display: flex;
            top: auto;
            margin: auto;
            padding: 0;
            width: 1.25rem;

            .mat-icon {
                color: $text-input-icon;
            }
        }

        .mat-select-arrow-wrapper {
            transform: none;
        }

        &.payhoa-search {
            margin-right: 1rem;

            .mat-icon {
                transform: scale(0.85) translate(8px, 0);
            }

            @media (max-width: 768px) {
                padding-right: 1rem;

                .mat-form-field-infix {
                    width: 100%;
                }
            }
        }

        &.wide-field {
            .mat-form-field-infix {
                width: 320px;

                mat-select-trigger {
                    .text-ellipsis {
                        max-width: 300px;
                    }
                }
            }

            @media (max-width: 768px) {
                .mat-form-field-wrapper,
                .mat-form-field-infix {
                    width: 100%;
                }
            }
        }
    }

    &:not(.mat-form-field-disabled) {
        &.mat-form-field-appearance-outline {
            .mat-form-field-flex:hover,
            &.mat-focused {
                .mat-form-field-outline-start,
                .mat-form-field-outline-gap,
                .mat-form-field-outline-end {
                    border-color: $mat-primary-color !important;
                }
            }
        }
    }

    .mat-select-placeholder {
        @include mat-text-color($text-placeholder);
    }

    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        @include mat-text-color($text-placeholder);
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        @include mat-text-color($text-placeholder);
    }

    :-ms-input-placeholder {
        /* IE 10+ */
        @include mat-text-color($text-placeholder);
    }

    :-moz-placeholder {
        /* Firefox 18- */
        @include mat-text-color($text-placeholder);
    }

    .mat-input-element,
    .mat-select-value-text {
        @include mat-text-color($text-input, false);
    }
}

.payhoa-table-actions {
    .mat-form-field.payhoa-field {
        &.mat-form-field-appearance-outline {
            &.payhoa-search {
                @media (max-width: 768px) {
                    padding-right: 0;

                    .mat-form-field-wrapper {
                        width: 100%;
                    }
                }
            }
        }
    }

    // tags-input
}
